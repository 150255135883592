import React, { useEffect, useState } from "react";
import Select from 'react-select';
import * as Helper from './functions';
import Autocomplete from "react-google-autocomplete";
import Jobs from "./Jobs";

const AddAlert = (props) => {
    const [alertJobId, setAlertJobId] = useState(false);
    const [selJobId, setSelJobId] = useState(null);
    const [alertCity, setAlertCity] = useState(false);
    const [selCity, setSelCity] = useState(null);
    const [alertErr, setAlertErr] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);

    const constructor = () => {
        console.log('constructor')
    }

    const saveAlert = ()=>{
        if(!alertCity || !alertJobId){
            setAlertErr("Please select job title and city from the suggestions.");
        }else{
            setAlertErr("");
            setDisableSubmit(true);
            //Get user profile data.
            let data = Object.assign({}, alertCity,{ job_id:alertJobId });
            Helper.axiosInstance().post('job/save-job-alerts', data)
                .then((res) => {
                    setDisableSubmit(false);
                    setSelJobId({label:'Select...', value:null});
                    setSelCity("");
                    setAlertCity(null);
                    setAlertJobId(null);
                    if (res.data.success) {
                        props.loadAlerts && props.loadAlerts();
                        window.$('#add-alert').modal('hide');
                    }
                    props.setMessage(res.data.message);
                    window.$("#jsCommonModal").modal('show');
                })
                .catch((error) => {
                    setDisableSubmit(false);
                });
        }
    };

     return (
         <>
        <div className="modal fade" id="add-alert" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">Add Job Alert
                <button type="button" className="close" onClick={()=>setAlertErr('')} data-dismiss="modal"><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <div className="setting_table" style={{ overflowX: "auto" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>Job Title</td>
                        <td>
                          <div className="form-group" key={`job${selJobId}`}>
                              {/*<Select options={props.jobs} defaultValue={selJobId} onChange={(e)=>{ setAlertErr(''); setAlertJobId(e.value); }} title="Job title"/>*/}
                              <Jobs selectedJob={selJobId} onChange={(e)=>{ setAlertErr(''); setSelJobId(e); setAlertJobId(e.value); }} placeholder={"Type to select..."} />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>City</td>
                        <td>
                          <div className="form-group" key={`city${selCity}`}>
                              <Autocomplete
                                  id="jsAddress"
                                  className="form-control specialStyle"
                                  placeholder="Search by city name..."
                                  autocomplete={false}
                                  defaultValue={selCity}
                                  onPlaceSelected={(place) => {
                                        setAlertErr('');
                                        setAlertCity(Helper.getAddressComponents(place.address_components));
                                    }
                                  }
                                  types={['(cities)']}
                                  componentRestrictions={{country: ["us", "ca"]}}
                              />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="save_cancel">
                    <button className="button-style" disabled={disableSubmit} onClick={()=>saveAlert()}>{disableSubmit ?  <Helper.btnLoader /> : `Save`}</button>
                  </div>
                    <Helper.ErrorMsg msg={alertErr} />
                </div>
              </div>
            </div>
          </div>
        </div>
     </>
  );
};

export default AddAlert;
