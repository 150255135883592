import React, { Component, createContext } from "react";
import * as Storage from './storage';
//import { auth, generateUserDocument } from '../firebase';

export const UserContext = createContext({
    user: null,
    toggleTheme: () => {}
});
class UserProvider extends Component {
    render() {
        return (
            <UserContext.Provider value={Storage.Get('user')}>{this.props.children}</UserContext.Provider>
        );
    }
}
export default UserProvider;