import React, {useEffect, useState} from "react";
import Header from "../Header/Header";
import {Link, navigate} from "@reach/router";
import Select from 'react-select';
import * as Helper from '../functions';
import {distance, expYears, hourlyRate, jobCompleted} from "../enum";
import Pagination from "react-js-pagination";
import '../pagination.css'
import AddAlert from "../AddAlert";

const Trade = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const [tradeList, setTradeList] = useState([]);
    const [exp, setExp] = useState(null);
    const [rate, setRate] = useState(null);
    const [jobId, setJobId] = useState(urlParams.get('s'));
    const [address, setAddress] = useState(urlParams.get('a'));
    const [jobsCompleted, setJobsCompleted] = useState(null);
    const [radius, setRadius] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [showPagination, setShowPagination] = useState(false);
    const [noRecord, setNoRecord] = useState(false);
    const [perPage, setPerPage] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [showSort, setShowSort] = useState(false);
    const [profileId, setProfileId] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [sortDir, setSortDir] = useState(null);
    const [showFilter, setShowFilter] = useState(null);
    const [jobProfiles, setJobProfiles] = useState({});
    const [message, setMessage] = useState('');

    const NotFoundText = () => (<div className="not_found" key="not_found">
                <h3>
                    No matches were found but here are{" "}
                    {
                        jobProfiles.length > 0
                            ? <>people looking for a service provider like you. Send them a request if their service interests you.</>
                            : <>some matches from your list of needs.</>
                    }
                    {" "}Please click here{" "}<a href="#add-alert" data-toggle="modal">click here</a>{" "}to add the alert.
                </h3>
            </div>);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        loadData();
    }, [exp, rate, radius, jobsCompleted, profileId, jobId, address, sortBy, sortDir]);

    const TradeList = () => {
        let arr = [];
        if(tradeList.length > 0) {
            if(noRecord){
                arr.push(<NotFoundText />);
            }
            for (let i in tradeList) {
                arr.push(<div className="search_main" key={i} onClick={()=>navigate(`/profile/${tradeList[i].profile_id}/${tradeList[i].req_job_id}`)}>
                    <div className="client_img">
                    { tradeList[i].photo && <img style={{
                        'width': '100px',
                        'height': '100px'
                    }} src={tradeList[i].photo} alt={tradeList[i].first_name} /> }
                    </div>
                    <div className="profile_tab_content">
                        <h4>{tradeList[i].first_name} {tradeList[i].last_name}</h4>
                        <Link className="remove_underline" to={`/profile/${tradeList[i].profile_id}/${tradeList[i].req_job_id}`}>
                            <p className="designer">Amazing{" "}
                                <u>{tradeList[i].job_title}</u>{" "}
                                looking for{" "}
                                <u>{tradeList[i].req_job_title}</u>
                            </p>
                        </Link>
                        <ul>
                            <li>
                                <span>{tradeList[i].experience}</span>{" "}
                                years of experience</li>
                            <li>
                                <span>${tradeList[i].hourly_rate}</span>
                                /hr</li>
                            <li>
                                <span>{tradeList[i].jobs_completed}</span>{" "}
                                jobs completed{" "}</li>
                        </ul>
                        <p className="description">{tradeList[i].overview}</p>
                    </div>
                    <div className="country_certify ml-auto">
                        <div className="icon_box">
                            <span>
                                <i className="fa fa-map-marker" aria-hidden="true" />{tradeList[i].address_business}</span>
                            <span>
                                <i className="fa fa-certificate" aria-hidden="true" />{tradeList[i].qualification}</span>
                        </div>
                        <Helper.showRating rating={tradeList[i].avg_rating} count={tradeList[i].review_count} />
                    </div>
                </div>
            )}
            showPagination && arr.push(<Pagination
                activePage={activePage}
                itemsCountPerPage={perPage}
                totalItemsCount={totalItems}
                pageRangeDisplayed={5}
                onChange={loadData}
            />);
        }else{
            arr.push(<NotFoundText />);
        }
        return (
            <>{arr}</>
        );
    };

    const loadData = (page=1)=>{
        setActivePage(page);
        setShowLoader(true);
        //Get user profile data.
        let data = {
            exp:exp,
            rate:rate,
            jobs_completed:jobsCompleted,
            radius:radius,
            profileId:profileId,
            jobId:jobId,
            address:address,
            sortBy:sortBy,
            sortDir:sortDir,
            page: page
        };
        Helper.axiosInstance().post('trade/list', data)
            .then((res) => {
                setNoRecord(res.data.noRecord);
                setTradeList(res.data.data);
                setTotalItems(res.data.total);
                setJobProfiles(res.data.jobProfiles);
                setShowPagination(res.data.showPagination);
                setPerPage(res.data.perPage);
                setShowLoader(false);
            })
            .catch((error) => { });
    };

    const sortData = (by, dir) => {
        setSortBy(by);
        setSortDir(dir);
        setShowSort(false);
    }
    let filterStyle = showFilter ? {display:showFilter} : {};

     return (
    <div>
      <Header page="trade" address={address} jobId={jobId} setAddress={setAddress} setJobId={setJobId} />
      <section className="sidebar_custom">
        <div className="container-fluid">
          <div className="row content">
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 sidenav side_filter">
              <div className="filterlist_heading">
                <h5><span className="web_filter">Filter Results</span></h5>
              </div>

                    <div className="filter_list" style={filterStyle} >
                        {jobProfiles.length ?
                            <div className="form-group">
                                <Select options={jobProfiles} onChange={e => setProfileId(e.value)} placeholder="Search as"
                                        isSearchable={false}/>
                            </div>
                            : null
                        }
                        <div className="form-group">
                          <Select options={distance} onChange={e=>setRadius(e.value)} placeholder="Distance" isSearchable={false} />
                        </div>
                        <div className="form-group">
                          <Select options={hourlyRate} placeholder="Hourly Rate" onChange={e=>setRate(e.value)} isSearchable={false} />
                        </div>
                        <div className="form-group">
                          <Select options={expYears} placeholder="Experience" onChange={e=>setExp(e.value)} isSearchable={false} />
                        </div>
                        <div className="form-group">
                          <Select options={jobCompleted} placeholder="Jobs Completed" onChange={e=>setJobsCompleted(e.value)} isSearchable={false} />
                        </div>
                      </div>

              <div className="search_alert show_desktop">
                <div className="alert_box_content">
                  <h4>Job Alerts</h4>
                  <p>Get notified when new listings match your search criteria</p>
                  <a href="#add-alert" data-toggle="modal" className="add-alert_btn">
                    <i className="fa fa-plus-circle" aria-hidden="true" />Add Alert
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 search ">
              <div className="search_listing">
                <div className="filterlist_heading">
                  <h5><span className="mobile_filter" onClick={()=>setShowFilter(!showFilter || showFilter == 'none' ? 'block' : 'none' )}>Filter Results</span></h5>
                </div>
                  {!showLoader &&
                  <div className="sort_by">
                      <h5 onClick={() => setShowSort(!showSort)}>Sort by{" "}<i
                          className={`fa fa-angle-${showSort ? 'up' : 'down'}`} aria-hidden="true"/></h5>
                      {
                          showSort ?
                              <div className="profile_user_login sort">
                                  <ul className="sortFilter">
                                      <li><Link to="" onClick={() => sortData('distance', 'asc')}>Distance: Low to High</Link></li>
                                      <li><Link to="" onClick={() => sortData('distance', 'desc')}>Distance: High to Low</Link></li>
                                      <li><Link to="" onClick={() => sortData('jobs_completed', 'asc')}>Jobs Completed: Low to High</Link></li>
                                      <li><Link to="" onClick={() => sortData('jobs_completed', 'desc')}>Jobs Completed: High to Low</Link></li>
                                      <li><Link to="" onClick={() => sortData('review_count', 'asc')}>Reviews: Low to High</Link></li>
                                      <li><Link to="" onClick={() => sortData('review_count', 'desc')}>Reviews: High to Low</Link></li>
                                      {/*<li><Link to="" onClick={() => sortData('experience', 'asc')}>Years of experience Asc</Link></li>
                                      <li><Link to="" onClick={() => sortData('experience', 'desc')}>Years of experience Desc</Link></li>*/}
                                  </ul>
                              </div>
                              : null
                      }
                  </div>
                  }
              </div>
                {showLoader ? <Helper.Loading /> : <TradeList />}
              <div className="search_alert show_mobile">
                <div className="alert_box_content">
                  <h4>Job alerts</h4>
                  <p>Get notified when new listings match your search criteria</p>
                  <a href="#manage-alert" data-toggle="modal" className="add-alert_btn">
                    <i className="fa fa-plus-circle" aria-hidden="true" />Add Alert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
          <AddAlert setMessage={setMessage} />
          <Helper.commonModal message={message} />
      </section>
    </div>
  );
};

export default Trade
