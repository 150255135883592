import React from "react";
import { Link } from "@reach/router";

const VerifyEmail = (props) => {
  return (
    <div className="modal-box">
      <div className="modal-dialog">
        <div className="modal-content">
          <Link to="/" className="my-2 text-blue-700 hover:text-blue-800 text-center block" style={{top: 5}} onClick={props.onCloseRequest}>x</Link>
          <div className="py-3 bg-green-400 w-full text-left mb-3" style={{margin: 0}}>An email verification mail has been sent to you!</div>
        </div>
      </div>
    </div>
  );
};
export default VerifyEmail;
