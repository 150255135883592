import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import { Link } from "@reach/router";
import * as Helper from './functions';
import Select from "react-select";

const JobProfile = (props) => {
    const [profile, setProfile] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [message, setMessage] = useState('');
    const [jobProfiles, setJobProfiles] = useState({});
    const [profileId, setProfileId] = useState(null);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [hireRequestSent, setHireRequestSent] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);

        //Get user profile data.
        Helper.axiosInstance().get(`job/profile/${props.id}${props.reqJobId ? '/'+props.reqJobId : ''}`)
            .then((res) => {
                setShowLoader(false);
                setProfile(res.data.profile);
                setReviews(res.data.reviews);
                setHireRequestSent(!!res.data.hireRequestSent);
                setJobProfiles(res.data.jobProfiles);
            })
            .catch((error) => {
            });
    }, []);
    let photoCnt = 0;
    profile.photo1 && photoCnt++;
    profile.photo2 && photoCnt++;
    profile.photo3 && photoCnt++;

    const ReviewList = () => {
        let arr = [];
        if(reviews.length > 0) {
            for (let i in reviews) {
                arr.push(<div className="reviews">
                            <div className="client_img">
                                <img className="user_thumb" src={reviews[i].photo} title={`${reviews[i].first_name} ${reviews[i].last_name}`}/>
                            </div>
                            <div className="reviews_content">
                                <h4>Review for {profile.job_title} job</h4>
                                <p>"{reviews[i].review}"</p>
                            </div>
                            <div className="ratings">
                                <p>{reviews[i].created_at}</p>
                                <Helper.showRating rating={reviews[i].rating} />
                            </div>
                        </div>
                )}
        }
        return (arr);
    };

    const sendJobRequest = (type) => {
        console.log('type', type)
        if(type == 'trade' && !profileId){
            setError("Please select the profile.");
        }else {
            setError("");
            setMessage('Sending your request please wait...');
            window.$('#trade-profile').modal('hide');
            window.$('#modal-container-5').modal('show');
            setDisableSubmit(true);
            const data = {
                to_user_id: profile.user_id,
                type: type,
                to_job_id: parseInt(profile.job_id),
                by_profile_id: profileId,
                to_profile_id: parseInt(props.id),
            };
            Helper.axiosInstance().post('trade/request', data)
                .then((res) => {
                    setMessage(res.data.message);
                    setDisableSubmit(false);
                    if(type == 'hire'){
                        setHireRequestSent(true);
                    }
                })
                .catch((error) => {
                    setDisableSubmit(false);
                });
        }
    }

    return (
        <div>
            <Header />
            <section className="profile">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#home" role="tab" data-toggle="tab">profile</a>
                                </li>
                                 {profile.photo1 || profile.photo2 || profile.photo3 ?
                                    <li className="nav-item">
                                        <a className="nav-link" href="#menu1" role="tab" data-toggle="tab">view my work ({photoCnt})</a>
                                    </li>
                                : null }
                                {reviews.length > 0 ?
                                    <li className="nav-item">
                                        <a className="nav-link" id="reviews" href="#menu2" role="tab" data-toggle="tab">reviews ({reviews.length})</a>
                                    </li>
                                : null }
                            </ul>
                            <div className="tab-content mb-3">

                                <div id="home" className="container tab-pane active">
                                    <br/>
                                    {showLoader ? <Helper.Loading /> :
                                    <>
                                    <div className="profile_tab">
                                        <div className="client_img">
                                            <img src={profile.photo} alt="" style={{
                                                'width': '100px',
                                                'height': '100px'
                                            }} />
                                        </div>
                                        <div className="profile_tab_content">
                                            <h4>{profile.first_name}{" "}{profile.last_name} {profile.status ? null : `(Inactive)`}</h4>
                                            <p className="designer">Amazing {profile.job_title}
                                                { profile.req_job_title && <> looking for{" "} {profile.req_job_title} </>}</p>
                                            <ul>
                                                <li><span>{profile.experience}</span> years experience</li>
                                                <li><span>${profile.hourly_rate}</span>/hr</li>
                                                <li><span>{profile.jobs_completed}</span> jobs completed</li>
                                            </ul>
                                        </div>
                                        <div className="location_star ml-auto">
                                            <div className="map_icon">
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>{profile.address_business}</div>
                                            <div className="cerficate_icon">
                                                <i className="fa fa-certificate" aria-hidden="true"></i>
                                            {profile.qualification}</div>
                                            <Helper.showRating rating={profile.avg_rating} count={profile.review_count} />
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="skills">
                                        <h5>skills</h5>
                                        <span className="coming_soon">Coming soon</span>
                                        {profile.overview ?
                                            <div className="overview_text">
                                                <h5>overview</h5>
                                                <p>{profile.overview}</p>
                                            </div>
                                        : null }
                                    </div>
                                    </> }
                                </div>
                                {profile.photo1 || profile.photo2 || profile.photo3 ?
                                    <div id="menu1" className="container tab-pane fade">
                                        <br/>
                                        <div className="work_images">
                                            {profile.photo1 ? <div className="img"><img src={profile.photo1} alt="" /></div> : null}
                                            {profile.photo2 ? <div className="img"><img src={profile.photo2} alt="" /></div> : null}
                                            {profile.photo3 ? <div className="img"><img src={profile.photo3} alt="" /></div> : null}
                                        </div>
                                    </div>
                                : null }
                                {reviews.length > 0 ? <div id="menu2" className="container tab-pane fade"><ReviewList /></div> : null }
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="Send_Trade_Offer">
                                {jobProfiles.length ?
                                <>
                                    <a className="trade_btn btn" href={"#trade-profile"} data-toggle="modal">Send Trade Request</a>
                                    <div className="or_circle">
                                        <span>or</span>
                                    </div>
                                </> : null }
                                {
                                    hireRequestSent ?
                                        <a className="trade_btn btn hire_btn disabled">Hire Request Pending</a>
                                        : <a className="trade_btn btn hire_btn" onClick={()=>sendJobRequest('hire')} data-toggle="modal">Send Hire Request</a>
                                }

                                <Link className="Back_to" to="/trade">Back to Search Results</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade c_info_custom" id="modal-container-5" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body1">
                                <button type="button" className="close" data-dismiss="modal">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div className="contact_info_main success_modal">
                                    <div className="contact_info_img">
                                        <img src="images/11.png" alt="" />
                                    </div>
                                    <div className="contact_info_content">
                                        <h5>{message}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="trade-profile" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Select your profile to trade with
                                <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="setting_table" style={{ overflowX: "auto" }}>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>Profile</td>
                                            <td>
                                                <div className="form-group" key={`job`}>
                                                    {jobProfiles.length ?
                                                        <div className="form-group">
                                                            <Select options={jobProfiles} onChange={e => {setError(''); setProfileId(e.value);}}
                                                                    isSearchable={false}/>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="save_cancel">
                                        <button className="button-style" disabled={disableSubmit} onClick={()=>sendJobRequest('trade')}>{disableSubmit ?  <Helper.btnLoader /> : `Send Trade Request`}</button>
                                    </div>
                                    <Helper.ErrorMsg msg={error} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default JobProfile
