import React, {useRef} from 'react';
import { Link } from "@reach/router";
import * as Helper from '../../functions';

const WebProfileDropDown = (props) => {
    const wrapperRef = useRef(null);
    props.useOutsideAlerter(wrapperRef);
    return (
        <div className="profile_user_login" ref={wrapperRef}>
                  <div className="pagelet-top-menu__account-button-container pagelet-top-menu__element">
                    <div className="account-menu mc-popover">
                      <span
                        role="button"
                        tabIndex={0}
                        aria-haspopup="true"
                        aria-expanded="true"
                        aria-disabled="false"
                        className="mc-popover-trigger"
                        aria-label="Account menu"
                      >
                        <div className="pic_and_text">
                          <div className="login_user_image">
                              <Link to="/setting">
                            {props.user && <img
                              style={{ marginLeft: 0, marginRight: "15px" }}
                              src={props.user.photo}
                              alt=""
                            />}</Link>
                          </div>
                          <div className="username_text">
                              <Link to="/setting">{props.user && <span>{props.user.first_name} {props.user.last_name}</span>}</Link>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <Link to="/setting">Settings</Link>
                          </li>
                          <li>
                            <a href="javascript: void(0);" onClick={()=>Helper.logout()}>Sign out</a>
                          </li>
                        </ul>
                      </span>
                    </div>
                  </div>
                </div>
    )
}

export default WebProfileDropDown