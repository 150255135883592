import React, {useRef, useState} from "react";
import { navigate } from "@reach/router";
import Autocomplete from "react-google-autocomplete";
import Jobs from "../Jobs";

const SelectSearch = (props) => {
    const [selectedJob, setSelectedJob] = useState(null);
    const [error, setError] = useState(null);
    const wrapperRef = useRef(null);
    props.useOutsideAlerter(wrapperRef);

    const search = () => {
        let a = document.getElementById('jsAddress') ? document.getElementById('jsAddress').value : '',
            s = selectedJob ? selectedJob.value : '';

        if(!a && !s){
            setError('Please select a service and a city');
        }else if(!a){
            setError('Please select a city');
        }else if(!s){
            setError('Please select a service');
        }else{
            if(props.page && props.page === 'trade'){
                props.setAddress(a);
                props.setJobId(s);
            }else{
                navigate('/trade?s='+s+'&a='+a);
            }
            props.setShowSearch(false);
        }
    };
    document.getElementById("jsAddress") && document.getElementById("jsAddress").addEventListener("focus", function (){
        this.autocomplete = "off";
    });

    return (
        <section className="select_search" ref={wrapperRef}>
            <div className="container-fluid">
                <div className="wrap_box">
                    <div className="row center_select">
                        <h2>Find a service provider, fill in fields below: </h2>
                        <div className="seacrh_item col-sm-3 col-xs-12">
                            <div className="form-group">
                                {/*<Select className="sl_serv_srh" options={props.services} defaultValue={props.jobId} onChange={(e)=>setJobId(e.value)} id="jsService" placeholder="Choose Service"/>*/}

                                <Jobs className="sl_serv_srh" selectedJob={selectedJob} setSelectedJob={setSelectedJob} />
                            </div>
                        </div>
                        <div className="seacrh_item col-sm-3 col-xs-12">
                            <div className="form-group">
                                {/*<input type="text" className="form-control specialStyle" defaultValue={props.address} id="jsAddress" name="address" placeholder="Search by city name..." />*/}
                                <Autocomplete
                                    id="jsAddress"
                                    className="form-control specialStyle"
                                    placeholder="Search by city name..."
                                    autocomplete={false}
                                    defaultValue={props.address}
                                    onPlaceSelected={(place) => {
                                        console.log('place', place);
                                    }}
                                    types={['(cities)']}
                                    componentRestrictions={{country: ["us", "ca"]}}
                                />
                            </div>
                        </div>
                        <div className="seacrh_item search_icon">
                            <div className="form-group">
                                <button type="button" className="search_btn btn">
                                    <span className="fa fa-search" onClick={()=>search()} />
                                </button>
                            </div>
                        </div>
                        {
                            error ?
                                <div className="seacrh_item col-sm-6 col-xs-12 err_msg">
                                    {error}
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SelectSearch;
