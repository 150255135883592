import React, {useRef} from "react";
import { Link } from "@reach/router";

const NotificationWebDropDown = (props) => {
  const wrapperRef = useRef(null);
  props.useOutsideAlerter(wrapperRef);

  const Notifications = () => {
    let arr = [];

    let row = '';
    if(props.notifications.length) {
      for (let i in props.notifications) {
        row = props.notifications[i];
        arr.push(<li>
              <Link to={row.link} className={"notificationLink"}>
                <div className="date notificationLink">{row.created_at}</div>
                <div className="user_img">
                  <img
                      className="feed-image feed-image--bluenote notificationLink"
                      src={row.photo}
                      alt=""
                      width={32}
                      height={32}
                  />
                </div>
                <div className="list_value notificationLink">
                  {row.title}
                </div>
              </Link>
            </li>
        )
      };
    }else{
      arr.push(<li>No Notification.</li>);
    }
    console.log('arr', arr)
    return (arr)
  };

  return (
    <div className="notidropdown" ref={wrapperRef}>
      <div className="pagelet-top-menu__account-button-container pagelet-top-menu__element">
        <div className="account-menu mc-popover">
          <span
            role="button"
            tabIndex={0}
            aria-haspopup="true"
            aria-expanded="true"
            aria-disabled="false"
            className="mc-popover-trigger"
            aria-label="Account menu"
          >
            <h2 className="notification_title">Notifications</h2>
            <ul>
              <Notifications />
            </ul>
          </span>
        </div>
      </div>
    </div>
  );
};

export default NotificationWebDropDown;
