import React, {useEffect, useRef, useState} from "react";
import {navigate} from "@reach/router";
import logo from '../logo.png';
import search from '../search.jpg';
import tools from '../tools.jpg';
import calender from '../calender.jpg';
import docs from '../docs.jpg';
import time from '../time.jpg';
import car from '../car.png';
import phoneImg from '../phone.jpg';
import location from '../location.jpg';
import computer from '../computer.jpg';
import camera from '../camera.jpg';
import final from '../final.jpg';
import facebook from '../facebook.png';
import linked from '../linked.png';
import insta from '../insta.png';
import ProgressBar from '../Components/ProgressBar';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import * as Helper from './functions';
import * as Storage from './storage';
import {SuccessAlert} from './Modals';
import Autocomplete from 'react-google-autocomplete';
import {distance, expYears, GoogleMapKey, hourlyRate, qualification} from "./enum";
import '../App.css';
import Geocode from "react-geocode";
import Jobs from "./Jobs";

const Timeline = () => {
    const [percentage, setPercentage] = useState(Storage.Get('percentage') ? Storage.Get('percentage') : 7.7);
    const [service, setService] = useState('');
    const [jobId, setJobId] = useState({});
    const [jobTitle, setJobTitle] = useState('');
    const [exp, setExp] = useState('');
    const [qualify, setQualify] = useState('');
    const [rate, setRate] = useState('');
    const [travel, setTravel] = useState('');
    const [mobile, setMobile] = useState('');
    const [mobileErr, setMobileErr] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneErr, setPhoneErr] = useState('');
    const [address, setPlace] = useState('');
    const [website, setWebsite] = useState('');
    const [zip, setZip] = useState('');
    const [face, setFacebook] = useState('');
    const [faceErr, setFaceErr] = useState('');
    const [link, setLinked] = useState('');
    const [linkErr, setLinkErr] = useState('');
    const [inst, setInsta] = useState('');
    const [instErr, setInstErr] = useState('');
    const [first, setFirst] = useState('');
    const [second, setSecond] = useState('');
    const [third, setThird] = useState('');
    const [userProfile, setUserProfile] = useState('');
    const [disableError, setDisableError] = useState('');
    const [errorSuggest, setErrorSuggest] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [disableSuggestBtn, setDisableSuggestBtn] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const suggestRef = useRef(null);
    const tranType = Storage.Get('tran', false);
    const percentageUnit = Storage.Get('tran', false) == 'h' ? 50 : 7.7;

    const goToDashboard = () => {
        Helper.axiosInstance().post('user/save-field', {fields: {timeline_complete: 1}})
            .then((res) => {
                let user = Storage.Get('user');
                user.timeline_complete = 1;
                Storage.Set('user', JSON.stringify(user));
            })
            .catch((error) => { });
        navigate('/dashboard?showSearch=true');
    };

    const onChangeSelect = (fld, e) => {
        if (fld === "jobId") {
            setJobTitle(e.label);
            setJobId(e);
            setDisableError('');
            saveField({job_id: e.value});
        }
    }

    const onChangeHandler = event => {
        const { name, value } = event.currentTarget;
        //console.log(name, value);
        if (name === "service") {
            setService(value);
        }else if (name === "year") {
            setExp(value);
            saveField({experience: value});
        } else if (name === "qualify") {
            setQualify(value);
            saveField({qualification: value});
        } else if (name === "rate") {
            setRate(value);
            saveField({hourly_rate: value});
        } else if (name === "travel") {
            setTravel(value);
            saveField({travel_limit: value});
        } else if (name === "mobile") {
            if(value == null || value.replace(/_/g, "").replace('(','').replace(')','').replace('-','').replace(' ','').length == 10){
                setMobileErr('');
            }
            setMobile(value);
        } else if (name === "phone") {
            if (value == null || value.replace(/_/g, "").replace('(','').replace(')','').replace('-','').replace(' ','').length == 10){
                setPhoneErr('');
            }
            setPhone(value);
        } else if (name === "place") {
            setPlace(value);
        } else if (name === "website") {
            setWebsite(value);
        } else if (name === "zip") {
            setZip(value);
        } else if (name === "facebook") {
            console.log('value', value, Helper.validFbUrl(value))
            if(!value || Helper.validFbUrl(value)){
                setFaceErr(false);
            }
            setFacebook(value);            
        } else if (name === "linked") {
            if(!value || Helper.validLinkedUrl(value)){
                setLinkErr(false);
            }
            setLinked(value);            
        } else if (name === "insta") {
            if(!value || Helper.validInstaUrl(value)){
                setInstErr(false);
            }
            setInsta(value);            
        } else if (name === "first") {
            setFirst(value.currentTarget);            
        } else if (name === "second") {
            setSecond(value.currentTarget);
        } else if (name === "third") {
            setThird(value.currentTarget);            
        } else if (name === "userProfile") {
            setUserProfile(value);            
        }
        setDisableError('');

    };

    const onBlurHandler = event => {
        const { name, value } = event.currentTarget;
        if (name === "mobile") {
            saveField({mobile: value});
        } else if (name === "phone") {
            saveField({phone_business: value});
        } else if (name === "place") {
            saveField({address_business: value});
        } else if (name === "website") {
            saveField({website: value});
        } else if (name === "zip") {
            if (Helper.isValidZip(zip)) {
                Geocode.setApiKey(GoogleMapKey);
                Geocode.fromAddress(zip).then(
                    response => {
                        const { lat, lng } = response.results[0].geometry.location;
                        let address = Object.assign({},
                            Helper.getAddressComponents(response.results[0].address_components),
                            {
                                lat: lat,
                                lng: lng,
                                zip: value,
                                profile_complete: 1,
                                status: 1
                            });
                        saveField(address);
                    },
                    error => {
                        console.error(error);
                    }
                );
            }else if(!zip){
                saveField({zip: ''});
            }
        } else if (name === "facebook") {
            saveField({facebook: value});
        } else if (name === "linked") {
            saveField({linkedin: value});
        } else if (name === "insta") {
            saveField({instagram: value});
        }
    };

    const saveField = (data) => {
        Helper.axiosInstance().post('user/save-profile-field', {fields: data})
            .then((res) => {
                //console.log('response', res);
            })
            .catch((error) => { });
    }

    const saveUserField = (data) => {
        Helper.axiosInstance().post('user/save-field', {fields: data})
            .then((res) => {
                //console.log('response', res);
            })
            .catch((error) => { });
    }

    useEffect(()=> {
        document.body.style.background = '#FFFFFF';
        Helper.redirectDashboard();
        //Get user profile data.
        Helper.axiosInstance().get('user/timeline')
            .then((res) => {
                setShowLoader(false);
                let user = res.data.user;
                setService(user.req_job);
                setJobId({value:user.profile.job_id, label: user.profile.job_title});
                setJobTitle(user.profile.job_title);
                setExp(user.profile.experience);
                setQualify(user.profile.qualification);
                setRate(user.profile.hourly_rate);
                setTravel(user.profile.travel_limit);
                setMobile(user.profile.mobile);
                setPhone(user.profile.phone_business);
                setPlace(tranType == 'h' ? user.address : user.profile.address_business);
                Storage.Set('address', user.profile.address_business);
                setWebsite(user.profile.website);
                setZip(user.profile.zip);
                setFacebook(user.profile.facebook);
                setLinked(user.profile.linkedin);
                setInsta(user.profile.instagram);
                setFirst(user.profile.photo1);
                setSecond(user.profile.photo2);
                setThird(user.profile.photo3);
                setUserProfile(user.photo);
            })
            .catch((error) => { });
    }, []);

    useEffect(()=> {
        Storage.Set('percentage', percentage);
        //document.getElementById('place') && (document.getElementById('place').value = Storage.Get('address', false));
    }, [percentage]);

    const disableHandle = () => {
        setPercentage(percentage + percentageUnit)
        setDisableError('');
    }

    const handleImage = (file, type) => {
        if(file[0].type != 'image/jpeg'){
            setDisableError('Please upload jpg image only.')
            return false;
        }else if(file[0].size > 5250000){
            setDisableError('You can upload max 5MB image.')
            return false;
        }else{
            setDisableError('')
        }
        let imgUrl = URL.createObjectURL(file[0]);
        if(type == 'photo1'){
            setFirst(imgUrl);
        }else if(type == 'photo2'){
            setSecond(imgUrl);
        }else if(type == 'photo3'){
            setThird(imgUrl);
        }else if(type == 'user'){
            setUserProfile(imgUrl);
        }

        if (file[0] !== null && file[0] !== undefined) {
            const formData = new FormData();
            formData.append('photo', file[0]);
            formData.append('type', type);
            Helper.axiosInstance().post('user/update-profile-image', formData,
                {
                    'Content-Type': 'multipart/form-data',
                }
            )
            .then((res) => {
                if(type == 'user') {
                    let user = Storage.Get('user');
                    user.photo = res.data.imageUrl;
                    Storage.Set('user', JSON.stringify(user));
                }
            })
            .catch((error) => { });
        }
    }

    const saveRequiredServices = (service) => {
        if(service == null){
            service = [];
        }
        if(service && service.length > 5){
            setDisableError('You can select maximum 5 services.');
            return;
        }
        setService(service);
        setDisableError('');
        const data = {
            jobs:service
        };
        Helper.axiosInstance().post('job/save-required-services', data)
            .then((res) => { })
            .catch((error) => { });
    }

    const validatePhone = ()=>{
        let m =false, p = false, me =false, pe = false;

        if(Helper.invalidPhone(mobile)){
            setMobileErr('Please enter a 10 digit mobile number');
            me = true;
        }else if(!mobile ||  mobile == '(___) ___-____' ){
            setMobileErr('');
        }else{
            setMobileErr('');
            m = true;
        }
        if (Helper.invalidPhone(phone)){
            setPhoneErr('Please enter a 10 digit phone number');
            pe = true;
        }else if(!phone || phone == '(___) ___-____' ){
            setPhoneErr('');
        }else{
            setPhoneErr('');
            p = true;
        }

        if(!me && !pe && (m || p)){
            disableHandle();
        }else if(!m && !p){
            setDisableError('Please enter at least 1 number.');
        }
    }

    const validateSocialLinks = ()=>{

        let f =true, l = true, i =true;

        if(face && !Helper.validFbUrl(face)){
            setFaceErr(true);
            f = false;
        }if(inst && !Helper.validInstaUrl(inst)){
            setInstErr(true);
            i = false;
        }if(link && !Helper.validLinkedUrl(link)){
            setLinkErr(true);
            l = false;
        }
        if(f && i && l){
            disableHandle();
        }
    }

    const previous = () => {
        setPercentage(percentage - percentageUnit)
        setDisableError('')
    }

    const suggestNewService = (event) => {
        event.preventDefault();
        if(!suggestion){
            setErrorSuggest('Please enter the service name.');
        }else if(!/^[a-zA-Z\s]*$/.test(suggestion)){
            setErrorSuggest('Only alphabets are allowed.');
        }else{
            setErrorSuggest('');
            const data = {job: suggestion};
            setDisableSuggestBtn(true);
            Helper.axiosInstance().post('job/request-new', data)
                .then((res) => {
                    setDisableSuggestBtn(false);
                    if(res.data.success){
                        setSuggestion('');
                        document.getElementsByClassName("close")[0].click();
                        setShow(true);
                        suggestRef.current.value ='';
                        setSuccessMsg('Thank you! Your suggestion has been sent successfully.')
                    }else{
                        setErrorSuggest(res.data.message);
                    }
                })
                .catch((error) => { });
        }
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="bgWhite">
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
            <ProgressBar percentage={percentage} />
            {showLoader ?  <div className="thanks"><Helper.Loading/></div> :
                <fieldset className="timeline_one">
                    {percentage > 0 && percentage <= 7.8 ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={search} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">
                                {tranType == 't' ? <>What services are you looking for to trade with?</>
                                : <>What service provider are you looking to hire?</>}</h2>
                            <div className="job1"></div>
                            <Jobs selectedJob={service} onChange={(e)=>saveRequiredServices(e)} isMulti={true} />
                            <br/>
                            <div className="col-md-12">Service not in the list? <a href="#modal-container-suggest"
                                       className="link" onClick={()=>{ setErrorSuggest(''); suggestRef.current.value =''}} data-toggle="modal">suggest new</a></div>
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button
                                onClick={service != null && service.length > 0 && service.length <= 5 ? disableHandle : () => setDisableError('Please choose suitable option (max 5) before moving next')}
                                className="trade-btn">Next
                            </button>
                            <div className="group_of_text_input">{disableError !== null ?
                                <div className="valid-error">{disableError}</div> : null}</div>
                        </div>
                        : null}
                    {tranType == 't' && percentage > 7.8 && percentage <= 15.5 ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={tools} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">What do you do?</h2>
                            <Jobs selectedJob={jobId} onChange={(e)=>onChangeSelect("jobId", e)} />

                            <div>Service not in the list? <a href="#modal-container-suggest" onClick={()=>{ setErrorSuggest(''); suggestRef.current.value =''}} className="link"
                                                             data-toggle="modal">suggest new</a></div>
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button
                                onClick={jobId.value > 0 ? disableHandle : () => setDisableError('Please choose suitable option before moving next')}
                                className="trade-btn">Next
                            </button>
                            <div className="group_of_text_input">{disableError !== null ?
                                <div className="valid-error">{disableError}</div> : null}</div>
                        </div>
                        : null}
                    {tranType == 't' && percentage > 15.5 && percentage <= 23.1 ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={calender} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">How many years have you been a
                                <span>{jobTitle}?</span>
                            </h2>
                            <select className="selectpicker form-control custom_sel specialStyle" name="year"
                                    value={exp} onChange={event => onChangeHandler(event)} title="looking for a.."
                                    required>
                                {Helper.addOptions(expYears)}
                            </select>
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button
                                onClick={exp && exp !== '0' ? disableHandle : () => setDisableError('Please choose suitable option before moving next')}
                                className="trade-btn">Next
                            </button>
                            <div className="group_of_text_input">{disableError !== null ?
                                <div className="valid-error">{disableError}</div> : null}</div>
                        </div>
                        : null}
                    {tranType == 't' && percentage > 23.1 && percentage <= 30.8 ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={docs} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">What are your qualifications as a
                                <span>{jobTitle}?</span>
                            </h2>
                            <select className="selectpicker form-control custom_sel specialStyle" name="qualify"
                                    value={qualify} onChange={event => onChangeHandler(event)} required>
                                {Helper.addOptions(qualification)}
                            </select>
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button
                                onClick={qualify && qualify !== '0' ? disableHandle : () => setDisableError('Please choose suitable option before moving next')}
                                className="trade-btn">Next
                            </button>
                            <div className="group_of_text_input">{disableError !== null ?
                                <div className="valid-error">{disableError}</div> : null}</div>
                        </div>
                        : null}
                    {tranType == 't' && percentage > 30.8 && percentage <= 38.5 ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={time} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">What is your hourly rate as a
                                <span>{jobTitle}?</span>
                            </h2>
                            <select className="selectpicker form-control custom_sel specialStyle" name="rate"
                                    value={rate} onChange={event => onChangeHandler(event)} title="looking for a.."
                                    required>
                                {Helper.addOptions(hourlyRate)}
                            </select>
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button
                                onClick={rate && rate !== '0' ? disableHandle : () => setDisableError('Please choose suitable option before moving next')}
                                className="trade-btn">Next
                            </button>
                            <div className="group_of_text_input">{disableError !== null ?
                                <div className="valid-error">{disableError}</div> : null}</div>
                        </div>
                        : null}
                    {tranType == 't' && percentage > 38.5 && percentage <= 46.2 ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={car} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">How far are you willing to travel to complete the trade?</h2>
                            <select className="selectpicker form-control custom_sel specialStyle" name="travel"
                                    value={travel} onChange={event => onChangeHandler(event)} title="looking for a.."
                                    required>
                                {Helper.addOptions(distance)}
                            </select>
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button
                                onClick={travel && travel !== '0' ? disableHandle : () => setDisableError('Please choose suitable option before moving next')}
                                className="trade-btn">Next
                            </button>
                            <div className="group_of_text_input">{disableError !== null ?
                                <div className="valid-error">{disableError}</div> : null}</div>
                        </div>
                        : null}
                    {tranType == 't' && percentage > 46.2 && percentage <= 59.3 ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={phoneImg} className="trade-logo" alt="phone"/>
                            </div>
                            <h2 className="thanks-title">What are your contact phone numbers?</h2>
                            <p>(Enter at least one phone number)</p>
                            <div className="inputs_group mobileInputs">
                                <div className="group_of_text_input">
                                    <span>Mobile</span>
                                    <InputMask type="text" name="mobile" value={mobile}
                                               onBlur={event => onBlurHandler(event)}
                                               onChange={event => onChangeHandler(event)} mask="(999) 999-9999"
                                               pattern="^\d{4}-\d{3}-\d{4}$" required/>
                                </div>
                                <div className="group_of_text_input">{mobileErr ?
                                    <div className="valid-error">{mobileErr}</div> : null}</div>
                                <div className="group_of_text_input">
                                    <span>Business</span>
                                    <InputMask type="text" name="phone" value={phone}
                                               onBlur={event => onBlurHandler(event)}
                                               onChange={event => onChangeHandler(event)} mask="(999) 999-9999"
                                               pattern="^\d{4}-\d{3}-\d{4}$" required/>
                                </div>
                                <div className="group_of_text_input">{phoneErr ?
                                    <div className="valid-error">{phoneErr}</div> : null}</div>
                            </div>
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button onClick={() => validatePhone()} className="trade-btn">Next</button>
                            <div className="group_of_text_input">{disableError !== null ?
                                <div className="valid-error">{disableError}</div> : null}</div>
                        </div>
                        : null}
                    {(tranType == 't' && percentage > 59.3 && percentage <= 67) ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={location} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">What is your business address?</h2>
                            <p>(Enter at least one)</p>
                            <div className="inputs_group mobileInputs">
                                <div className="group_of_text_input">
                                    <span>Address</span>
                                    <Autocomplete
                                        id="place"
                                        style={{width: '80%'}}
                                        defaultValue={address}
                                        onBlur={(e)=>{
                                            setPlace('');
                                            tranType == 'h' ? saveUserField({address:''}) : saveField({address_business:''}) ;
                                        }}
                                        onPlaceSelected={(place) => {
                                            setPlace(place.formatted_address);
                                            let addressField = tranType == 'h' ? 'address' : 'address_business';
                                            let address = Object.assign({},
                                                Helper.getAddressComponents(place.address_components),
                                                {
                                                    [addressField]: place.formatted_address,
                                                    lat: place.geometry.location.lat(),
                                                    lng: place.geometry.location.lng(),
                                                    profile_complete: 1,
                                                    status: 1
                                                });
                                            tranType == 'h' ? saveUserField(address) : saveField(address) ;
                                            setDisableError('');
                                        }}
                                        types={['address']}
                                        componentRestrictions={{country: ["us", "ca"]}}
                                    />
                                </div>
                                <div className="group_of_text_input">{mobileErr ?
                                    <div className="valid-error">{mobileErr}</div> : null}</div>
                                <div className="group_of_text_input">
                                    <span>Zip/Postal Code</span>
                                    <input style={{'width':'82%'}} type="text" maxLength={7} placeholder="Zip/Postal Code"
                                           name="zip" value={zip} onBlur={event => onBlurHandler(event)}
                                           onChange={event => onChangeHandler(event)} required></input>
                                </div>
                                <div className="group_of_text_input">{phoneErr ?
                                    <div className="valid-error">{phoneErr}</div> : null}</div>
                            </div>
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button
                                onClick={()=>{
                                    if(!address && !zip){
                                        setDisableError('Please enter the business address or business zip/postal Code.');
                                    }else if(zip && !Helper.isValidZip(zip)){
                                        setDisableError('Please enter a valid zip/postal Code.');
                                    }else{
                                        disableHandle();
                                    }
                                }
                                }
                                className="trade-btn">Next
                            </button>
                            <div className="group_of_text_input">{disableError !== null ?
                                <div className="valid-error">{disableError}</div> : null}</div>
                        </div>
                        : null}
                    {tranType == 't' && percentage > 67 && percentage <= 74.7 ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={computer} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">What is your business website URL?</h2>
                            <p>(This is optional)</p>
                            <div className="inputs_group mobileInputs">
                                <input className="maximum_width_input" type="text" placeholder="www.job4job.com"
                                       name="website" value={website} onBlur={event => onBlurHandler(event)}
                                       onChange={event => onChangeHandler(event)} required></input>
                            </div>
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button
                                onClick={!website || Helper.validURL(website) ? disableHandle : () => setDisableError('Please enter a valid URL.')}
                                className="trade-btn">Next
                            </button>
                            {disableError !== null ? <div className="group_of_text_input">
                                <div className="valid-error">{disableError}</div>
                            </div> : null}
                            <button onClick={disableHandle} className="skip-style">Skip</button>
                        </div>
                        : null}
                    {tranType == 't' && percentage > 74.7 && percentage <= 82.4 ?
                        <div className="thanks">
                            <div className="reload social_media_timeline">
                                <img src={facebook} alt="facebook"/>
                                <img src={linked} alt="linked"/>
                                <img src={insta} alt="instagram"/>
                            </div>
                            <h2 className="thanks-title">Social Media</h2>
                            <p>(This is optional)</p>
                            <div className="inputs_group mobileInputs">
                                <div className="group_of_text_input">
                                    <span>Facebook</span>
                                    <input type="text" name="facebook" value={face} placeholder="facebook.com/j4j"
                                           onBlur={event => onBlurHandler(event)}
                                           onChange={event => onChangeHandler(event)} required/>
                                </div>
                                <div className="group_of_text_input">{faceErr ?
                                    <div className="valid-error">Enter a valid Facebook URL.</div> : null}</div>
                                <div className="group_of_text_input">
                                    <span>LinkedIn</span>
                                    <input type="text" name="linked" value={link} placeholder="linkedin.com/j4j"
                                           onBlur={event => onBlurHandler(event)}
                                           onChange={event => onChangeHandler(event)} required/>
                                </div>
                                <div className="group_of_text_input">{linkErr ?
                                    <div className="valid-error">Enter a valid Linkedin URL.</div> : null}</div>
                                <div className="group_of_text_input">
                                    <span>Instagram</span>
                                    <input type="text" name="insta" value={inst} placeholder="instagram.com/j4j"
                                           onBlur={event => onBlurHandler(event)}
                                           onChange={event => onChangeHandler(event)} required/>
                                </div>
                                <div className="group_of_text_input">{instErr ?
                                    <div className="valid-error">Enter a valid Instagram URL.</div> : null}</div>
                            </div>
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button onClick={() => validateSocialLinks()} className="trade-btn">Next</button>
                            {disableError !== null ? <div className="group_of_text_input">
                                <div className="valid-error">{disableError}</div>
                            </div> : null}
                            <button onClick={disableHandle} className="skip-style">Skip</button>
                        </div>
                        : null}
                    {tranType == 't' && percentage > 82.4 && percentage <= 90 ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={camera} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">Upload photos of your work as a
                                <span style={{marginBottom: 0}}>{jobTitle}</span>
                            </h2>
                            <p>*No nudity (jpg images only)</p>
                            <div className="three_img_uploader">
                                <div className="image_uploader">
                                    <div className="image_preview_main">
                                        <div className={first !== '' ? "upload_preview" : ""}>
                                            <Dropzone onDrop={acceptedFiles => handleImage(acceptedFiles, 'photo1')}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {first === '' ?
                                                                <div className={first === '' ? "image_preview" : ""}>
                                                                    <h5>Image 1</h5>
                                                                    <p>Click here to upload the photo</p>
                                                                </div>
                                                                : <img src={first} width="100%" alt="upload"/>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </div>
                                <div className="image_uploader">
                                    <div className="image_preview_main">
                                        <div className={second !== '' ? "upload_preview" : ""}>
                                            <Dropzone onDrop={acceptedFiles => handleImage(acceptedFiles, 'photo2')}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {second === '' ?
                                                                <div className={second === '' ? "image_preview" : ""}>
                                                                    <h5>Image 2</h5>
                                                                    <p>Click here to upload the photo</p>
                                                                </div>
                                                                : <img src={second} width="100%" alt="upload"/>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </div>
                                <div className="image_uploader">
                                    <div className="image_preview_main">
                                        <div className={third !== '' ? "upload_preview" : ""}>
                                            <Dropzone onDrop={acceptedFiles => handleImage(acceptedFiles, 'photo3')}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {third === '' ?
                                                                <div className={third === '' ? "image_preview" : ""}>
                                                                    <h5>Image 3</h5>
                                                                    <p>Click here to upload the photo</p>
                                                                </div>
                                                                : <img src={third} width="100%" alt="upload"/>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {disableError !== null ? <div className="group_of_text_input">
                                <div className="valid-error">{disableError}</div>
                            </div> : null}
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button onClick={() => setPercentage(percentage + percentageUnit)}
                                    className="trade-btn">Next
                            </button>
                            <button onClick={() => setPercentage(percentage + percentageUnit)}
                                    className="skip-style">Skip
                            </button>
                        </div>
                        : null}
                    {(tranType == 't' && percentage > 90 && percentage <= 97.7) || (tranType == 'h' && percentage > 50 && percentage <= 99) ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={camera} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">Upload your profile picture</h2>
                            <p>*No nudity</p>
                            <div className="three_img_uploader">
                                <div className="image_uploader">
                                    <div className="image_preview_main">
                                        <div className={userProfile ? "upload_preview" : ""}>
                                            <Dropzone onDrop={acceptedFiles => handleImage(acceptedFiles, 'user')}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {!userProfile ?
                                                                <div className={!userProfile ? "image_preview" : ""}>
                                                                    <h5>Image 1</h5>
                                                                    <p>Click here to upload the photo</p>
                                                                </div>
                                                                : <img src={userProfile} width="100%" alt="upload"/>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {disableError !== null ? <div className="group_of_text_input">
                                <div className="valid-error">{disableError}</div>
                            </div> : null}
                            <button className={percentage <= 7.8 ? "trade-off" : "trade-on"}
                                    onClick={() => previous()}
                                    disabled={percentage === 0 ? true : null}>Previous
                            </button>
                            <button onClick={() => setPercentage(percentage + percentageUnit)}
                                    className="trade-btn">Next
                            </button>
                            <button onClick={() => setPercentage(percentage + percentageUnit)}
                                    className="skip-style">Skip
                            </button>
                        </div>
                        : null}
                    {percentage > 97.7 && percentage <= 110 ?
                        <div className="thanks">
                            <div className="reload">
                                <img src={final} className="trade-logo" alt="loading"/>
                            </div>
                            <h2 className="thanks-title">What happens now ?</h2>
                            <p>Start searching for talented people to get your work done.</p>
                            {/*<p className="optional_text">(Don't worry if you are unable to find a match today, our
                                system will automatically alert you once they join Job4Job)</p>*/}
                            <button className="search-btn" onClick={goToDashboard}>Search Now</button>
                            <button onClick={() => previous()} className="back-style">Go
                                Back
                            </button>
                        </div>
                        : null}
                </fieldset>
            }
            <div className="modal fade" id="modal-container-suggest" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body custom_body">
                            <h2>Suggest New Service</h2>
                            <form onSubmit={event=>suggestNewService(event)}>
                                <input type="text" maxLength="50" ref={suggestRef}  onChange={(event) => setSuggestion(event.currentTarget.value) && setErrorSuggest('')}
                                       className="leave_coment" placeholder="Service Name" required/>
                                {errorSuggest && <div className="valid-error2">{errorSuggest}</div>}
                                <div style={{clear: "both"}}></div>
                                <button className="add-my-review" disabled={disableSuggestBtn}  type="submit">{disableSuggestBtn ? <Helper.btnLoader /> : `Submit`}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <SuccessAlert handleClose={handleClose} show={show} message={successMsg} />
        </div>
   </div>
   )
};
export default Timeline;
