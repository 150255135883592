import React, {useState, useEffect} from "react";
import {Link} from "@reach/router";
import SelectSearch from "./SelectSearch";
import NotificationWebDropDown from "./NotificationDropdown/NotificationWebDropDown";
import WebProfileDropDown from "./ProfileDropDown/WebProfileDropDown";
import MobileProfileDropDown from "./ProfileDropDown/MobileProfileDropDown";
import * as Storage from '../storage';
import * as Helper from "../functions";

const Header = (props) => {
    const [showSearch, setShowSearch] = useState(props.showSearch ? props.showSearch : false);
    const [isNotification, setIsNotification] = useState(false);
    const [isProfile, setIsProfile] = useState(false);
    const [notifications, setNotifications] = useState(true);
    const [newCount, setNewCount] = useState(0);
    const [showLoader, setShowLoader] = useState(true);

    const onClickSearch = () => {
        setShowSearch(!showSearch);
        setIsNotification(false);
        setIsProfile(false);
        props.setAddress && props.setAddress('');
    }
    const onClickNotification = () => {
        console.log('isNotification', isNotification)
        setIsNotification(!isNotification);
        setIsProfile(false);
        Helper.axiosInstance().post('notification/mark-read', {})
            .then((res) => {
                setNewCount(0);
            })
            .catch((error) => {
            });
    }


    const onClickProfile = () => {
        setIsProfile(!isProfile);
        setIsNotification(false);
    }
    const user = Storage.Get('user');

    useEffect(() => {
        /*let source = new EventSource(process.env.REACT_APP_API_URL+"notification/get");
        source.onmessage = function(event) {
            console.log('event.data', event.data);
        };*/

        Helper.axiosInstance().get('notification/get-list')
            .then((res) => {
                setShowLoader(false);
                setNotifications(res.data.data);
                setNewCount(res.data.new);
            })
            .catch((error) => {
            });
    }, []);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && event.target.className.indexOf('pac-') !== 0
                    && event.target.className.indexOf('notificationLink') === -1 && event.target.className !== '') {
                    setShowSearch(false);
                    setIsProfile(false);
                    // setIsNotification(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    return (
        
        <section className="nav_section nav-down">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg ">
                    <Link className="navbar-brand col-md-3 col-3 col-sm-3" to="/dashboard">
                        <img src="/images/logo.png" alt=""/>
                    </Link>
                    <Link to="/dashboard">
                        <img className="bottom_nav" src="/images/logo_m.png" style={{'width':'40px'}} alt=""/>
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNav"

                    >
                        <span className="navbar-toggler-icon">
                            <i className="fa fa-bars"/>
                        </span>
                    </button>
                    <div className="user_login ml-auto mobile_menu">
                        <div className="notification_drp bottom_nav" >
                                <span className="notification " onClick={onClickSearch}>
                                    <i className="fa fa-search" aria-hidden="true"/>
                                </span>
                        </div>
                        <div className="notification_drp" onClick={onClickNotification}>
                            <span className="notification">
                                <i className="fa fa-bell-o" aria-hidden="true"/>
                                {newCount > 0 ? <span className="badge">{newCount}</span> : null}
                            </span>
                            {isNotification ?
                                <NotificationWebDropDown useOutsideAlerter={useOutsideAlerter} notifications={notifications} showLoader={showLoader}/> : null}
                        </div>
                        <div className="login_user_img" onClick={onClickProfile}>
                            {user ? <img src={props.user ? props.user.photo : user.photo} alt=""/> : null}
                            {isProfile ? <MobileProfileDropDown useOutsideAlerter={useOutsideAlerter} user={props.user ? props.user : user}/> : null}
                        </div>
                    </div>

                    <div className="collapse navbar-collapse" id="navbarNav">

                        <ul className="navbar-nav">
                            {/*<li className="nav-item active">
                                <Link className="tradeBtn nav-link top_nav" to="/trade">
                                     Find a service provider
                                    <span className="sr-only">(current)</span>
                                </Link>
                            </li>*/}
                            <li className={'nav-item bottom_nav '+ (props.page && props.page == 'dashboard' ? 'active' : '')}>
                                <Link className="nav-link" to="/dashboard">
                                    Dashboard
                                </Link>
                            </li>
                            <li className={'nav-item '+ (props.page && props.page == 'trade' ? 'active' : '')}>
                                <Link className="nav-link bottom_nav" to="/trade">
                                    Find a service provider
                                    <span className="sr-only">(current)</span>
                                </Link>
                            </li>
                            <li className={'nav-item bottom_nav '+ (props.page && props.page == 'status' ? 'active' : '')}>
                                <Link className="nav-link" to="/status">
                                    Status of Requests
                                </Link>
                            </li>
                        </ul>
                        {
                            props.showNotification ?  <div className="savedSuccess" >Saved Successfully!</div> : null
                        }
                        <div className="user_login ml-auto web_menu">
                            <div className="searchImage top_nav">
                                <span className="notification " onClick={onClickSearch}>
                                    <i className="fa fa-search" aria-hidden="true"/>
                                </span>
                            </div>

                            <div className="notification_drp" onClick={onClickNotification}>
                                <span className="notification">
                                    <i className="fa fa-bell-o" aria-hidden="true"/>
                                    {newCount > 0 ? <span className="badge">{newCount}</span> : null}
                                </span>
                                {isNotification ? <NotificationWebDropDown notifications={notifications}
                                                                   useOutsideAlerter={useOutsideAlerter} showLoader={showLoader}/> : null}
                            </div>
                            <div className="login_user_img" onClick={onClickProfile}>
                                {user ? <img src={props.user ? props.user.photo : user.photo} alt=""/> : null}
                                {isProfile ? <WebProfileDropDown useOutsideAlerter={useOutsideAlerter} user={props.user ? props.user : user}/> : null}
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            {showSearch ?
                <>
                <SelectSearch useOutsideAlerter={useOutsideAlerter} page={props.page}
                              setShowSearch={setShowSearch} setAddress={props.setAddress} setJobId={props.setJobId}
                              address={props.address} jobId={props.jobId}/>
                    <div id="overlay" onClick={()=>setShowSearch(false)}></div>
                </>
              : null}

        </section>
    );
};

export default Header;
