import React, {useState} from "react";
import {Link, navigate} from "@reach/router";
import * as Helper from './functions';

const PasswordReset = (props) => {
    const [email, setEmail] = useState("");
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [errorPass, setErrorPass] = useState(null);
    const [errorCPass, setErrorCPass] = useState(null);
    const [resetSuccess, setResetSuccess] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const onChangeHandler = event => {
        const {name, value} = event.currentTarget;
        console.log('name, value', name, value)
        if (name === "userEmail") {
            setEmail(value);
            setError("");
        } else if (name === "password") {
            setPassword(value);
            setErrorPass("");
        } else if (name === "confirm_password") {
            setCPassword(value);
            setErrorCPass("");
        }
    };
    const sendResetEmail = event => {
        event.preventDefault();
        var validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;  //eslint-disable-line
        if (email === '') {
            setError("Email should not be empty");
        } else if (!email.match(validEmail)) {
            setError('Enter a Valid Email Address');
        } else {
            setDisableSubmit(true);
            Helper.axiosInstance().post('password/forgot', {email: email})
                .then((res) => {
                    setEmailHasBeenSent(true);
                    setError(null);
                    setTimeout(() => {
                        setEmailHasBeenSent(false)
                    }, 2000);
                    setTimeout(() => {
                        props.onCloseRequest()
                    }, 2000);
                    setDisableSubmit(false);
                })
                .catch((error) => {
                    console.error("Error signing in with password and email", error);
                    setDisableSubmit(false);
                });
        }
    };

    const resetPassword = event => {
        event.preventDefault();
        let valid = true;
        if (password === '') {
            setErrorPass("Password should not be empty");
            valid = false;
        } else if (!Helper.strongPass(password)) {
            setErrorPass('Password should contain minimum 8 mix characters');
            valid = false;
        }
        if (cpassword === '') {
            setErrorCPass("Confirm Password should not be empty");
            valid = false;
        } else if (password !== cpassword) {
            setErrorCPass('Password and confirm password should match');
            valid = false;
        }
        if (valid) {
            setDisableSubmit(true);
            Helper.axiosInstance().post('password/reset', {
                token: props.token,
                password: password,
                confirm_password: cpassword,
            })
                .then((res) => {
                    if (res.data.success) {
                        setResetSuccess(true);
                        setErrorPass('');
                    } else {
                        setErrorPass(res.data.message);
                    }
                    setDisableSubmit(false);
                })
                .catch((error) => {
                    console.error("Error signing in with password and email", error);
                    setDisableSubmit(false);
                });
        }
    };
    return (
        <div className="modal-box">
            <div className="modal-dialog">
                <div className="modal-content">
                    {
                        props.section == 'rp' ?
                            <>
                                <h2>Reset password</h2>
                                <Link to="" className="my-2 text-blue-700 hover:text-blue-800 text-center block"
                                      onClick={props.onCloseRequest}>x</Link>
                                <div className="content">Enter new password and confirm password to reset your password.
                                </div>
                                <div>
                                    <form>
                                        <input type="password" name="password" placeholder="Password"
                                               onChange={e=>onChangeHandler(e)} required/>
                                        {errorPass !== null && (<div className="valid-error4">{errorPass}</div>)}
                                        <input type="password" name="confirm_password" placeholder="Confirm Password"
                                               onChange={e=>onChangeHandler(e)} required/>
                                        {errorCPass !== null && (<div className="valid-error4">{errorCPass}</div>)}
                                        <button className="button-style" disabled={disableSubmit} onClick={(event) => {
                                            resetPassword(event)
                                        }}>{disableSubmit ? <Helper.btnLoader/> : `Reset Password`}
                                        </button>
                                    </form>
                                    {resetSuccess && (
                                        <div className="py-3 bg-green-400 w-full text-left mb-3">Password reset
                                            successfully, please login to continue.</div>)}
                                </div>
                            </>
                            :
                            props.section == 'vt' ?
                                <>
                                    <h2>Reset Password</h2>
                                    <Link to="" className="my-2 text-blue-700 hover:text-blue-800 text-center block"
                                          onClick={props.onCloseRequest}>x</Link>
                                    <div className="content">{props.rpMsg}</div>
                                </>
                                :
                                <>
                                    <h2>Forgotten your password?</h2>
                                    <Link to="" className="my-2 text-blue-700 hover:text-blue-800 text-center block"
                                          onClick={props.onCloseRequest}>x</Link>
                                    <div className="content">Enter your email address to reset your password. You may
                                        need to check
                                        your spam folder or unblock no-reply@job4job.com.
                                    </div>
                                    <div>
                                        <form>
                                            <input type="email" name="userEmail" id="userEmail" value={email}
                                                   placeholder="Email"
                                                   onChange={e=>onChangeHandler(e)} required/>
                                            {error !== null && (<div className="valid-error4">{error}</div>)}
                                            <button className="button-style" disabled={disableSubmit} onClick={(event) => {
                                                sendResetEmail(event)
                                            }}>{disableSubmit ? <Helper.btnLoader/> : `Submit`}
                                            </button>
                                        </form>
                                        {emailHasBeenSent && (
                                            <div className="py-3 bg-green-400 w-full text-left mb-3">An email has been
                                                sent to
                                                you!</div>)}
                                    </div>
                                </>
                    }
                </div>
            </div>
        </div>
    );
};
export default PasswordReset;
