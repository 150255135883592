import React, {useEffect, useRef, useState} from "react";
import {Link, navigate} from "@reach/router";
import Header from "../Header/Header";
import * as Helper from "../functions";
import * as Storage from "../storage";
import StarRating from "react-rating";
import { NoRecord } from "../enum";
import Pagination from "react-js-pagination";
import '../pagination.css'

const StatusTradeHire = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [message, setMessage] = useState('');
  const [data, setData] = useState('');
  const [info, setInfo] = useState('');
  const [rating, setRating] = useState('');
  const [review, setReview] = useState('');
  const [profileId, setProfileId] = useState('');
  const [jobId, setJobId] = useState('');
  const [reviewId, setReviewId] = useState(null);
  const [jobRequestId, setJobRequestId] = useState('');
  const [error, setError] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showPagination, setShowPagination] = useState(false);
  const [perPage, setPerPage] = useState(0);
  const [index, setIndex] = useState(0);
  const [reviewPostedBy, setReviewPostedBy] = useState(null);
  const user = Storage.Get('user');

  const reviewRef = useRef(null);

  useEffect(()=> {
      window.scrollTo(0, 0);
    document.getElementById('row4') && (document.getElementById('row4').innerHTML = '<span>Request</span>');

    //Get user profile data.
    loadData();
  }, []);

  const loadData = (page=1)=>{
    setActivePage(page);
    setShowLoader(true);
    //Get user profile data.
    let data = {
      page: page
    };
    Helper.axiosInstance().post(`trade/requests`, data)
        .then((res) => {
          setShowLoader(false);
          setData(res.data.data);
          setTotalItems(res.data.total);
          setShowPagination(res.data.showPagination);
          setPerPage(res.data.perPage);
        })
        .catch((error) => {
        });
  };

  const TradeList = () => {
    let arr = [];
    for (let i in data) {
      let row = data[i];
      let toMe = row.to_user_id == user.id, photo = '', uname = '';
      if (toMe) {
        photo = row.by_photo;
        uname = row.by_first_name + ' ' + row.by_last_name;
      } else {
        photo = row.to_photo;
        uname = row.to_first_name + ' ' + row.to_last_name;
      }
      let link = '';
      if(toMe){
        link = `/profile/${row.by_profile_id}`;
      }else{
        link = `/profile/${row.to_profile_id}`;
      }
      let notHire = (row.type == 'trade' || (row.type == 'hire' && !toMe));
      arr.push(
          <div key={i} className="job_details" style={{'cursor': notHire ? 'pointer' : 'default'}}>
            <div className="user_img"  onClick={()=>notHire && navigate(link)}>
              <img src={photo} alt="" title={uname}/>
            </div>
            <div className="text"  onClick={()=>notHire && navigate(link)}>
              <h6>{uname}</h6>
              <>
                {toMe
                  ?
                  <>
                  {
                    row.type == 'trade' ?
                      <Link to={link}>
                        You as a {row.to_job_title} have received a trade request from a {row.by_job_title}.
                      </Link>
                    :
                      <>
                        You as a {row.to_job_title} have received a hire request.
                      </>
                  }
                  </>
                :
                  <>
                  {
                    row.type == 'trade' ?
                        <Link to={link}>
                          You as a {row.by_job_title} have sent a trade request to a {row.to_job_title}.
                        </Link>
                        :
                        <Link to={link}>
                          You have sent a hire request to a {row.to_job_title}.
                        </Link>
                  }
                  </>
                }
              </>
              <div className="img">
                <img src="images/5.png" alt=""/>
                <span>
                  {
                    row.type == 'trade'
                        ? <>{toMe ? data[i].by_address : data[i].to_address}</>
                        : <>{toMe ? data[i].by_user_address : data[i].to_address}</>
                  }
                  </span>
              </div>
            </div>

            {data[i].status == 'P' &&
            <>
              {toMe ?
                  <div className={`button_accept ml-auto`} id={`row${data[i].id}`}>
                    <Link to="" onClick={() => acceptDecline(data[i].id, 'A', i)}>accept</Link>
                    <Link id="decline" to="" onClick={() => acceptDecline(data[i].id, 'R', i)}>decline</Link>
                  </div>
                  : <div className="pending ml-auto">
                    <a href={"javascript:void:(0)"} className="contact_review" style={{'text-decoration':'none', 'cursor':'auto'}}><span><i className="fa fa-clock-o" aria-hidden="true"/></span>{data[i].type} request sent</a>
                  </div>
              }
            </>
            }
            {data[i].status == 'A' &&
            <div className="contact_info ml-auto">
              {row.type == 'trade' || !toMe
                ? <>
                <a onClick={() => fetchDetails(data[i].id)} data-toggle="modal" className="view_contact_info">
                  <span><i className="fa fa-check" aria-hidden="true"/></span>view contact info
                </a>
                {(toMe && data[i].to_review_id) || (!toMe && data[i].by_review_id)
                    ? <a className="contact_review" onClick={() => leaveReview(data[i], i, toMe, toMe ? data[i].to_review_id : data[i].by_review_id)} data-toggle="modal">Edit Review</a>
                    : <a className="contact_review" onClick={() => leaveReview(data[i], i, toMe, false)} data-toggle="modal">Leave a
                      review</a>
                }
                </>
                  :
                  <a href={"javascript:void:(0)"} className="contact_review" style={{'text-decoration':'none', 'cursor':'auto'}}>Request Accepted</a>
              }
            </div>
            }
            {data[i].status == 'R' &&
            <div className="contact_info ml-auto">
              <a href={"javascript:void:(0)"} className="contact_review" style={{'text-decoration':'none', 'cursor':'auto'}}>Request Declined</a>
            </div>
            }
          </div>
      )
    };

    showPagination && arr.push(
      <div style={{'margin-top':'40px'}}>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={perPage}
        totalItemsCount={totalItems}
        pageRangeDisplayed={5}
        onChange={loadData}
    /></div>);

    return (arr);
  };

  const acceptDecline = (id, status, i) => {
    setMessage('Please wait...');
    window.$('#jsCommonModal').modal('show');
    Helper.axiosInstance().post('trade/accept-decline', {id: id,status: status})
        .then((res) => {
          let tmp = data;
          tmp[i].status = status;
          setData(tmp)
          setMessage(res.data.message);
        })
        .catch((error) => { });
  }

  const fetchDetails = (id) => {
    window.$('#modal-info').modal('show');
    setInfo(false);
    Helper.axiosInstance().get(`trade/user-info/${id}`)
        .then((res) => {
          setInfo(res.data.info);
        })
        .catch((error) => { });
  }

  const leaveReview = (data, i, toMe, id) => {
    setInfo({
      name: toMe ? data.by_first_name+' '+data.by_last_name : data.to_first_name+' '+data.to_last_name,
      photo: toMe ?  data.by_photo : data.to_photo,
    });
    let tProfileId = toMe ? data.by_profile_id : data.to_profile_id,
        tJobId = toMe ? data.by_job_id : data.to_job_id;
    
    setProfileId(tProfileId);
    setJobId(tJobId);
    setJobRequestId(data.id);
    setReviewPostedBy(toMe ? 'to' : 'by');
    setIndex(i);
    setRating(null);
    setReview('');
    setError('');
    setReviewId(null);
    reviewRef.current.value = '';

    if(id){
      let postData = {
        review_id: id
      }
      setDisableSubmit(true);
      Helper.axiosInstance().post('job/get-review', postData)
          .then((res) => {
            setDisableSubmit(false);
            if (res.data.id) {
              console.log('res.data.review', res.data.review)
              setRating(res.data.rating);
              setReview(res.data.review);
              reviewRef.current.value = res.data.review;
              setReviewId(res.data.id);
            }
          })
          .catch((error) => {
            setDisableSubmit(false);
          });
    }


    window.$('#modal-review').modal('show');
  }

  const submitReview = () => {
    if(!rating){
      setError('Please select the rating.');
    }else if(!review){
      setError('Please enter the comment.');
    }else {
      setError('');
      setDisableSubmit(true);
      let postData = {
        rating: rating,
        review: review,
        user_profile_id: profileId,
        job_id: jobId,
        job_request_id: jobRequestId,
        review_id: reviewId,
        review_posted_by: reviewPostedBy,
      },
          url = reviewId > 0 ? 'update' : 'save';

      Helper.axiosInstance().post('job/'+url+'-review', postData)
          .then((res) => {
            setDisableSubmit(false);
            if (res.data.success) {
              window.$('#modal-review').modal('hide');
              reviewRef.current.value ='';
              let d = data;
              d[index][reviewPostedBy+'_review_id'] = res.data.id;
              setData(d);
            }
            setMessage(res.data.message);
            window.$("#jsCommonModal").modal('show');

          })
          .catch((error) => {
            setDisableSubmit(false);
          });
    }
  }

  return (
    <div>
      <Header page={"status"} />
      <section className="sidebar_custom status_remove">
        <div className="container-fluid">
          <div className="row content statsRes">
            <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 sidenav">
              <ul className="nav nav-pills nav-stacked ">
                <li><Link to="/dashboard">Dashboard</Link></li>
                <li className="active"><Link to="/status">Status of Requests</Link></li>
              </ul>
            </div>
            <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 right_section status no_padding">
              <div className="manage">
                <div className="details">
                  <div className="trades_left_main">
                    <span className="icon_img">
                      <i className="fa fa-check-square-o" aria-hidden="true" />{" "}<p className="f_size">Status of Requests</p>
                    </span>
                  </div>
                  {showLoader ? <Helper.Loading /> :
                      data.length > 0 ? <TradeList /> : <div style={{marginTop:'50px'}}>No pending updates available.</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="modal-review" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal_img">
                  <img className={"user_img_100"} src={info.photo} alt=""/>
                </div>
                <h5 className="modal-title" id="myModalLabel">{info.name}</h5>
                <h2>Leave a review</h2>
                <div className="location_star_status">
                  <div className="stars">
                    <StarRating
                        emptySymbol="fa fa-star-o "
                        fullSymbol="fa fa-star"
                        initialRating={rating}
                        onClick={(r)=>setRating(r) && setError('')}
                        fractions={1}
                    />
                  </div>
                </div>
                  <textarea rows={8} ref={reviewRef} defaultValue={review} maxLength={500} placeholder="Leave a comment"
                            onKeyUp={()=>setError('')} onBlur={(e)=>setReview(e.currentTarget.value)} />
                {error && <div className="err_msg">{error}</div>}
                  <button className="add-my-review" disabled={disableSubmit} onClick={()=>submitReview()} type="submit">{disableSubmit ? <Helper.btnLoader /> : `Submit`}</button>
              </div>
            </div>
          </div>
        </div>
        {/* view contact info */}
        <div className="modal fade c_info_custom" id="modal-info" role="dialog" aria-labelledby="myModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body1">
                <button type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">×</span>
                </button>
                <div className="contact_info_main">
                  {info ?
                  <>
                    <div className="contact_info_img">
                      <img className="user_img_100" src={info.photo} alt=""/>
                    </div>
                    <div className="contact_info_content">
                      {info.name && <p>{info.name}</p>}
                      {info.mobile && <h5>M: {info.mobile}</h5>}
                      {info.phone && <h5>P: {info.phone}</h5>}
                      {info.email && <h5>{info.email}</h5>}
                    </div>
                  </>
                    : <Helper.Loading />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Helper.commonModal message={message} />
    </div>

  );
};

export default StatusTradeHire;
