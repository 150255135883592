import React from "react";
export const SERVER_ERROR = "There was a problem connecting to the server.";
export const NoRecord = () => (<div style={{marginTop:'50px'}}>No record available.</div>);
export const SupportEmail = "support@job4job.com";
export const GoogleMapKey = "AIzaSyClNU8LYlOdRW4wSVqBMS6U25jo2SMpSYg";
export const distance = [
    { value: '25', label: 'Less than 25 miles' },
    { value: '50', label: '25 - 50 miles' },
    { value: '100', label: '50 - 100 miles' },
    { value: '99999', label: '100 miles+' },
    { value: '-1', label: 'Work Remotely' },
];

export const hourlyRate = [
    { value: '10-20', label: '$10 - 20/hr' },
    { value: '20-50', label: '$20 - 50/hr' },
    { value: '50-75', label: '$50 - 75/hr' },
    { value: '75-100', label: '$75 - 100/hr' },
    { value: '100+', label: '$100/hr+' }
];

export const expYears = [
    { value: '1-3', label: '1 - 3 years' },
    { value: '2-5', label: '2 - 5 years' },
    { value: '5-10', label: '5 - 10 years' },
    { value: '10+', label: '10+ years' }
];

export const jobCompleted = [
    { value: '0-20', label: '0 - 20 jobs' },
    { value: '20-50', label: '20 - 50 jobs' },
    { value: '50-9999', label: '50+ jobs' }
];

export const qualification = [
    { value: 'Certified', label: 'Certified' },
    { value: 'Licensed', label: 'Licensed' },
    { value: 'Skilled', label: 'Skilled' }
];