import React from "react";
import Application from "./Components/Application";
import UserProvider from "./Components/UserProvider";
function App() {
    return (
        <UserProvider>
            <Application />
        </UserProvider>
    );
}
export default App;
