import React, {useState, useEffect} from "react";
import Header from "../Header/Header";
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import * as Helper from '../functions';
import * as Storage from '../storage';
import Autocomplete from 'react-google-autocomplete';
import {distance, hourlyRate, expYears, qualification} from "../enum";
import InputMask from "react-input-mask";
import AddAlert from "../AddAlert";
import DeleteAlert from "../DeleteAlert";
import DeleteProfileAlert from "../DeleteProfilePic";
import Geocode from "react-geocode";
import {GoogleMapKey} from "../enum";
import Jobs from "../Jobs";


const Setting = () => {
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState("");
    const [options, setOptions] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [currentPass, setCurrentPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [error, setError] = useState('');
    const [errCurrP, setErrCurrP] = useState('');
    const [errCP, setErrCP] = useState('');
    const [errNP, setErrNP] = useState('');
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [allProfiles, setAllProfiles] = useState(0);
    const [selProfile, setSelProfile] = useState(0);
    const [alerts, setAlerts] = useState([]);
    const [delAlertId, setDelAlertId] = useState(0);
    const [jobs, setJobs] = useState([]);
    const [profileComplete, setProfileComplete] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showNPass, setShowNPass] = useState(false);
    const [showCPass, setShowCPass] = useState(false);
    const reqProfileFields = ['zip', 'experience', 'hourly_rate', 'job_id', 'mobile', 'qualification', 'travel_limit', 'req_jobs'];

    useEffect(() => {
        window.scrollTo(0, 0);

        Helper.axiosInstance().get('user/profile')
            .then((res) => {
                setShowLoader(false);
                setOptions(res.data.jobs);
                setUser(res.data.user);
                setAllProfiles(res.data.profiles);
                setProfile(res.data.profiles[0]);
                window.$('[data-toggle="tooltip"]').tooltip();
            })
            .catch((error) => {
            });
    }, []);

    useEffect(() => {
        let fld = '', errFld = '';
        console.log('profile', profile)
        if (profile) {
            for (let i in reqProfileFields) {
                fld = reqProfileFields[i];
                if (!profile[fld] || profile[fld].length == 0) {
                    errFld = fld + '_err';
                    setProfileState(errFld, 'This is a required field.');
                }
            }
        }
        setProfileComplete(errFld == '');
        console.log('errFld', errFld)
        if(errFld != ''){
            saveProfileField({status: 0}, false);
            setProfileState('status', 0);
        }

    }, [profile.address_business, profile.zip, profile.experience, profile.hourly_rate, profile.job_id, profile.mobile
        , profile.qualification, profile.travel_limit, profile.req_jobs]);

    const loadAlerts = () => {
        setShowLoader(true);
        Helper.axiosInstance().get('job/list-job-alerts')
            .then((res) => {
                setShowLoader(false);
                setAlerts(res.data.alerts);
                setJobs(res.data.jobs);
            })
            .catch((error) => {
                setShowLoader(false);
            });
    }

    const changeProfile = (n) => {
        if (allProfiles[n]) {
            setShowLoader(false);
            setProfile(allProfiles[n]);
            setSelProfile(n);
        } else {
            setShowLoader(true);
            setDisableSubmit(true);
            Helper.axiosInstance().post('user/add-profile', {})
                .then((res) => {
                    setShowLoader(false);
                    setDisableSubmit(false);
                    setProfile(Object.assign({}, res.data.profile));
                    let ap = allProfiles;
                    ap[n] = res.data.profile;
                    setAllProfiles(Object.assign({}, ap));
                    setSelProfile(n);
                })
                .catch((error) => {
                    setShowLoader(false);
                    setDisableSubmit(false);
                });
        }
    };

    const setUserState = (name, value) => {
        let usr = user;
        usr[name] = value;
        setUser(Object.assign({}, usr));
        if (name.indexOf("_err") == -1) {
            let user = Storage.Get('user');
            user[name] = value;
            Storage.Set('user', JSON.stringify(user));
        }
    }

    const setProfileState = (name, value) => {
        let p = profile;
        p[name] = value;
        setProfile(Object.assign({}, p));

        let allP = allProfiles;
        allP[selProfile] = Object.assign({}, p)
        setAllProfiles(Object.assign({}, allP));
    }

    const onBlurHandler = event => {
        const {name, value} = event.currentTarget;
        // console.log(name, value);
        let errFld = name + '_err', valid = true;
        if (value == '') {
            setUserState(errFld, 'Please enter a value.');
        } else if (name == 'email' && !Helper.isValidEmail(value)) {
            setUserState(errFld, 'Please enter a valid email address.');
        } else {
            let err = '';
            if (name == 'zip') {
                if (Helper.isValidZip(value)) {
                    Geocode.setApiKey(GoogleMapKey);
                    Geocode.fromAddress(value).then(
                        response => {
                            const {lat, lng} = response.results[0].geometry.location;
                            let address = Object.assign({},
                                Helper.getAddressComponents(response.results[0].address_components),
                                {
                                    lat: lat,
                                    lng: lng,
                                    zip: value
                                });
                            saveField(address);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                } else {
                    err = 'Please enter a valid zip/postal code.';
                }
            } else {
                saveField({[name]: value});
            }
            setUserState(name, value);
            setUserState(errFld, err);
        }
    };

    const onChangeSelect = (name, e) => {
        if (name === 'job_id') {
            setProfileState('job_title', e.label);
            setProfileState('job_id_err', '');
        }
        setProfileState(name, e);
        saveProfileField({
            id: profile.id,
            [name]: e.value
        });
    }

    const onChangeProfile = event => {
        const {name, value} = event.currentTarget;
        console.log(name, value)
        let errFld = name + '_err', valid = true;
        if (name == 'website' && value != '' && !Helper.validURL(value)) {
            setProfileState(errFld, 'Please enter a valid URL.');
            valid = false;
        } else if (name == 'facebook' && value != '' && !Helper.validFbUrl(value)) {
            setProfileState(errFld, 'Please enter a valid Facebook URL.');
            valid = false;
        } else if (name == 'instagram' && value != '' && !Helper.validInstaUrl(value)) {
            setProfileState(errFld, 'Please enter a valid Instagram URL.');
            valid = false;
        } else if (name == 'linkedin' && value != '' && !Helper.validLinkedUrl(value)) {
            setProfileState(errFld, 'Please enter a valid Linkedin URL.');
            valid = false;
        } else if (name != 'overview' && name != 'address_business' && name != 'phone_business' && (value == '0' || value == '')) {
            setProfileState(errFld, 'This is a required field.');
            valid = false;
        } else if ((name == 'mobile' && !Helper.validPhone(value)) || (name == 'phone_business' && value != '' && !Helper.validPhone(value))) {
            setProfileState(errFld, 'Please enter a 10 digit number.');
            valid = false;
        } else if ((name == 'zip' && value && !Helper.isValidZip(value))) {
            setProfileState(errFld, 'Please enter a valid zip/postal code.');
            valid = false;
        }

        if (valid) {
            //Address is not saved from this function this is for validation purpose only
            if (name == 'address_business') {
                if (profile.address_business != value && value != '') {
                    setProfileState(errFld, 'Please select the address from the suggestions. Manual entry is not permitted.');
                } else {
                    setProfileState(errFld, '');
                    saveProfileField({
                        id: profile.id,
                        [name]: value
                    });
                }
            } else if (name == 'zip') {
                if (Helper.isValidZip(value)) {
                    Geocode.setApiKey(GoogleMapKey);
                    Geocode.fromAddress(value).then(
                        response => {
                            const {lat, lng} = response.results[0].geometry.location;
                            setProfileState(name, value);
                            setProfileState(errFld, null);
                            let address = Object.assign({},
                                Helper.getAddressComponents(response.results[0].address_components),
                                {
                                    id: profile.id,
                                    lat: lat,
                                    lng: lng,
                                    zip: value
                                });
                            saveProfileField(address);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                } else {
                    setProfileState(errFld, 'Please enter a valid zip/postal code.');
                }
            } else {
                setProfileState(name, value);
                setProfileState(errFld, null);
                saveProfileField({
                    id: profile.id,
                    [name]: value
                });
            }
        }
    };

    const showSavedMsg = () => {
        setShowNotification(true);
        setTimeout(function () {
            setShowNotification(false);
        }, 2000);
    }

    const saveProfileField = (data, showMsg=true) => {
        let complete = (profile.zip && profile.experience && profile.hourly_rate && profile.job_id && profile.mobile
            && profile.qualification && profile.travel_limit && profile.req_jobs.length > 0) ? 1 : 0;
        data = Object.assign({}, data, {'profile_complete': complete, id: profile.id});
        Helper.axiosInstance().post('user/save-profile-field', {fields: data})
            .then((res) => {
                showMsg && showSavedMsg();
            })
            .catch((error) => {
            });
    }

    const saveField = (data) => {
        Helper.axiosInstance().post('user/save-field', {fields: data})
            .then((res) => {
                if (!res.data.success && res.data.field) {
                    setUserState(res.data.field + '_err', res.data.message);
                } else {
                    showSavedMsg();
                }
            })
            .catch((error) => {
            });
    }

    const saveNotification = e => {
        saveField({[e.currentTarget.name]: !e.currentTarget.checked});
        setUserState(e.currentTarget.name, !e.currentTarget.checked);
    }

    const setStatus = e => {
        if(profileComplete || e.currentTarget.checked){
            saveProfileField({[e.currentTarget.name]: !e.currentTarget.checked});
            setProfileState(e.currentTarget.name, !e.currentTarget.checked);
        }else{
            setMessage('Please complete all the required fields to activate this profile.');
            setMessageClass('message');
            window.$('#jsCommonModal').modal('show');
        }

    }

    const handleImage = (file, type) => {
        if (file[0].type != 'image/jpeg') {
            setUserState(type + 'PhotoError', 'Please upload jpg image only.')
            return false;
        } else if (file[0].size > 5250000) {
            setUserState(type + 'PhotoError', 'You can upload max 5MB image.')
            return false;
        } else {
            setUserState(type + 'PhotoError', '')
        }
        let imgUrl = URL.createObjectURL(file[0]);
        if (type == 'user') {
            setUserState('photo', imgUrl);
        } else {
            setProfileState(type, imgUrl);
        }

        if (file[0] !== null && file[0] !== undefined) {
            const formData = new FormData();
            formData.append('photo', file[0]);
            formData.append('type', type);
            if (type != 'user') {
                formData.append('profile_id', profile.id);
            }
            Helper.axiosInstance().post('user/update-profile-image', formData,
                {
                    'Content-Type': 'multipart/form-data',
                }
            )
                .then((res) => {
                    if (type == 'user') {
                        let user = Storage.Get('user');
                        user.photo = res.data.imageUrl;
                        Storage.Set('user', JSON.stringify(user));
                    }
                })
                .catch((error) => {
                });
        }
    }

    const saveRequiredServices = (service) => {
        if (service == null) {
            service = [];
        }
        if (service.length > 5) {
            setProfileState('req_jobs_err', 'You can select maximum 5 services.');
        } else {
            if (service.length == 0) {
                setProfileState('req_jobs_err', 'Please select the required services.');
            } else {
                setProfileState('req_jobs_err', '');
            }
            setProfileState('req_jobs', service);
            const data = {
                serve: service,
                profile_id: profile.id
            };
            Helper.axiosInstance().post('job/save-req-services-profile', data)
                .then((res) => {
                    saveProfileField({});
                })
                .catch((error) => {
                });
        }

    }

    const changePass = () => {
        let valid = true;
        if (currentPass == '') {
            setErrCurrP('Enter your current password');
            valid = false;
        }
        if (newPass == '') {
            setErrNP('Enter a strong password');
            valid = false;
        }
        if (!Helper.strongPass(newPass)) {
            setErrNP('Minimum 8 characters containing uppercase, lowercase and digit');
            valid = false;
        }
        if (confirmPass == '') {
            setErrCP('Enter same password as above');
            valid = false;
        }
        if (confirmPass !== newPass) {
            setErrCP('Password does not match');
            valid = false;
        }
        if (valid) {
            setErrCurrP("");
            setErrNP("");
            setErrCP("");
            setDisableSubmit(true);
            //Get user profile data.
            let data = {
                'current_password': currentPass,
                'password': newPass,
                'confirm_password': confirmPass,
            };
            Helper.axiosInstance().put('user/change-password', data)
                .then((res) => {
                    setDisableSubmit(false);
                    if (res.data.success) {
                        window.$('#change-pass').modal('hide');
                    }
                    setMessage(res.data.message);
                    window.$("#jsCommonModal").modal('show');
                })
                .catch((error) => {
                    setDisableSubmit(false);
                });
        }

    };

    const ListAlerts = () => {
        let arr = [];
        if (alerts.length > 0) {
            for (let i in alerts) {
                arr.push(<tr className="trade_alert">
                        <td>{alerts[i].title}</td>
                        <td>{alerts[i].country}</td>
                        <td>{alerts[i].city} <a className="delete_alert" data-toggle="modal"
                                                onClick={() => setDelAlertId(alerts[i].id)} href={"#delete-alert"}><i
                            className="fa fa-trash-o"
                            aria-hidden="true"></i></a></td>
                    </tr>
                )
            }
            ;
        } else {
            arr.push(<tr className="trade_alert">
                <td colSpan={3}>No record found.</td>
            </tr>);
        }

        return (arr)
    };

    return (
        <div>
            <Header user={user} showNotification={showNotification}/>
            <section className="profile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="nav nav-tabs">
                                <li className="nav-item"><a className="nav-link active" href="#tab1" role="tab"
                                                            data-toggle="tab">Your
                                    Profile</a></li>
                                <li className="nav-item"><a className="nav-link" href="#tab2" role="tab"
                                                            data-toggle="tab">Job Profiles</a>
                                </li>
                                {/*<!-- <li className="nav-item"><a className="nav-link" href="#tab3" role="tab" data-toggle="tab">Subscriptions</a></li>-->*/}
                                <li className="nav-item"><a className="nav-link" href="#tab4" role="tab"
                                                            data-toggle="tab">Notifications</a>
                                </li>
                                <li className="nav-item"><a className="nav-link" href="#tab5" role="tab"
                                                            data-toggle="tab" onClick={() => loadAlerts()}>Job
                                    Alerts</a></li>
                            </ul>
                            <div className="tab-content mb-3">
                                <div id="tab1" className="container tab-pane active"><br/>
                                    <div className="manage_job_bg">
                                        <div className="manage_job"><span><i
                                            className="fa fa-user"></i>Manage Profile</span></div>
                                        {showLoader ? <Helper.Loading/> :
                                            <>

                                                <div className="avatar-wrapper">
                                                    <div className="edit_upload">
                                                        <Dropzone
                                                            onDrop={acceptedFiles => handleImage(acceptedFiles, 'user')}>
                                                            {({getRootProps, getInputProps}) => (
                                                                <section>
                                                                    <div {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        <img className="profile-pic" src={user.photo}
                                                                             style={{height: 200}}
                                                                             alt="upload"/>
                                                                        {/*<div className="upload-button"><i className="fa fa-times"
                                                                            aria-hidden="true" onClick={(e)=>window.$("#delete-photo-alert").modal('show')}></i>
                                          </div>*/}

                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </div>
                                                {user.userPhotoError &&
                                                <div className={"text-center err_msg"}>{user.userPhotoError}</div>}
                                                {user.photo && user.photo.indexOf('defaultUser.png') > 0
                                                    ? null
                                                    :
                                                    <div style={{'textAlign': 'center'}}><a href={"javascript:void(0)"}
                                                                                            onClick={(e) => window.$("#delete-photo-alert").modal('show')}>Delete
                                                        Photo</a></div>
                                                }
                                                <div className={"text-center"}>*No nudity ( jpg images only )</div>
                                                <div className="setting_table" style={{overflowX: "auto"}}>
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <span className={"err_msg"}>*</span>Required fields
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className={"err_msg"}>*</span>First Name</td>
                                                            <td className="editable_cont" onClick={()=>document.getElementById("first_name").focus()}>
                                                                <input type="text"
                                                                                                 id="first_name"
                                                                                                 name="first_name"
                                                                                                 defaultValue={user.first_name}
                                                                                                 onBlur={event => onBlurHandler(event)}
                                                                                                 maxLength={100}
                                                                                                 placeholder="First Name"/>
                                                                <Helper.ErrorMsg msg={user.first_name_err}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className={"err_msg"}>*</span>Last Name</td>
                                                            <td className="editable_cont" onClick={()=>document.getElementById("last_name").focus()}>
                                                                <input type="text"
                                                                                                 id="last_name"
                                                                                                 name="last_name"
                                                                                                 defaultValue={user.last_name}
                                                                                                 maxLength={100}
                                                                                                 onBlur={event => onBlurHandler(event)}
                                                                                                 placeholder="Last Name"/>
                                                                <Helper.ErrorMsg msg={user.last_name_err}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className={"err_msg"}>*</span>Zip or Postal Code
                                                            </td>
                                                            <td className="editable_cont" onClick={()=>document.getElementById("zip").focus()}>
                                                                <input type="text" name="zip" id="zip"
                                                                                                 defaultValue={user.zip}
                                                                                                 maxLength={7}
                                                                                                 onBlur={event => onBlurHandler(event)}
                                                                                                 placeholder="Zip or Postal Code"/>
                                                                <Helper.ErrorMsg msg={user.zip_err}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className={"err_msg"}>*</span>Email</td>
                                                            <td className="editable_cont" onClick={()=>document.getElementById("email").focus()}><input style={{width: '80%'}}
                                                                                                 type="text"
                                                                                                 id="email"
                                                                                                 name="email"
                                                                                                 defaultValue={user.email}
                                                                                                 maxLength={100}
                                                                                                 onBlur={event => onBlurHandler(event)}
                                                                                                 placeholder="Email Address"/>
                                                                <Helper.ErrorMsg msg={user.email_err}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Password</td>
                                                            <td>
                                                                <a href="#change-pass" onClick={()=>{setErrCurrP("");
                                                                    setErrNP("");
                                                                    setErrCP("");}} className="changePassword"
                                                                   data-toggle="modal">Change</a>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div id="tab2" className="container tab-pane fade"><br/>
                                    <div className="manage_job_profile ">
                                        <div className="manage_job_bg">
                                            <div className="manage_job"><span><i className="fa fa-user"></i>Manage Job Profiles</span>
                                            </div>
                                            <div className="file_upload">
                                                <div onClick={() => !disableSubmit && changeProfile(0)}
                                                     className={`newProfilrTab btn profile-tab${selProfile === 0 ? '-active' : ''}`}>
                                                    {allProfiles[0] && allProfiles[0].job_title ? allProfiles[0].job_title :
                                                        <i className="fa fa-plus-circle" aria-hidden="true"></i>}
                                                    {allProfiles[0] ? allProfiles[0].status ? ` (Active)` : ` (Inactive)` : null}
                                                </div>
                                                <div onClick={() => !disableSubmit && changeProfile(1)}
                                                     className={`newProfilrTab btn profile-tab${selProfile === 1 ? '-active' : ''}`}>
                                                    {allProfiles[1] && allProfiles[1].job_title ? allProfiles[1].job_title :
                                                        <i className="fa fa-plus-circle" aria-hidden="true"></i>}
                                                    {allProfiles[1] ? allProfiles[1].status ? ` (Active)` : ` (Inactive)` : null}
                                                </div>
                                                <div onClick={() => !disableSubmit && changeProfile(2)}
                                                     className={`newProfilrTab btn profile-tab${selProfile === 2 ? '-active' : ''}`}>
                                                    {allProfiles[2] && allProfiles[2].job_title ? allProfiles[2].job_title :
                                                        <i className="fa fa-plus-circle" aria-hidden="true"></i>}
                                                    {allProfiles[2] ? allProfiles[2].status ? ` (Active)` : ` (Inactive)` : null}
                                                </div>
                                            </div>
                                            {showLoader ? <Helper.Loading/> :
                                                <>
                                                    <div className="file_upload">
                                                        <div className="file-upload-wrapper btn">
                                                            <Dropzone
                                                                onDrop={acceptedFiles => handleImage(acceptedFiles, 'photo1')}>
                                                                {({getRootProps, getInputProps}) => (
                                                                    <section>
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            {profile.photo1 ?
                                                                                <img src={profile.photo1} width="100%"
                                                                                     alt="upload"/>
                                                                                :
                                                                                <>
                                                      <span><i className="fa fa-upload fa-4x"
                                                               aria-hidden="true"></i></span>
                                                                                    <h4>upload photo</h4>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            {user.photo1PhotoError &&
                                                            <div
                                                                className={"text-center err_msg"}>{user.photo1PhotoError}</div>}
                                                        </div>
                                                        <div className="file-upload-wrapper btn">
                                                            <Dropzone
                                                                onDrop={acceptedFiles => handleImage(acceptedFiles, 'photo2')}>
                                                                {({getRootProps, getInputProps}) => (
                                                                    <section>
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            {profile.photo2 ?
                                                                                <img src={profile.photo2} width="100%"
                                                                                     alt="upload"/>
                                                                                :
                                                                                <>
                                                      <span><i className="fa fa-upload fa-4x"
                                                               aria-hidden="true"></i></span>
                                                                                    <h4>upload photo</h4>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            {user.photo2PhotoError &&
                                                            <div
                                                                className={"text-center err_msg"}>{user.photo2PhotoError}</div>}
                                                        </div>
                                                        <div className="file-upload-wrapper btn">
                                                            <Dropzone
                                                                onDrop={acceptedFiles => handleImage(acceptedFiles, 'photo3')}>
                                                                {({getRootProps, getInputProps}) => (
                                                                    <section>
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            {profile.photo3 ?
                                                                                <img src={profile.photo3} width="100%"
                                                                                     alt="upload"/>
                                                                                :
                                                                                <>
                                                      <span><i className="fa fa-upload fa-4x"
                                                               aria-hidden="true"></i></span>
                                                                                    <h4>upload photo</h4>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            {user.photo3PhotoError &&
                                                            <div
                                                                className={"text-center err_msg"}>{user.photo3PhotoError}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="setting_table editable" style={{overflowX: "auto"}}>
                                                        <table>
                                                            <tbody>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <span className={"err_msg"}>*</span>Fill in all the
                                                                    required fields to activate your profile
                                                                    {/*{ profileComplete
                                          ? <>Your profile is active.</>
                                          : <><span className={"err_msg"}>*</span>Required fields</>
                                      }*/}

                                                                    <div style={{margin: 0, float: 'right'}}>
                                                                        <span className={"job_status"}>Status</span>
                                                                        <label className="switch-label-new"
                                                                               style={{margin: 0}}>
                                                                            <input type="checkbox" name="status"
                                                                                   onClick={e => setStatus(e)}
                                                                                   checked={!profile.status}
                                                                                   className="switch-input"/>
                                                                            <span className="switch-label" data-on="On"
                                                                                  data-off="Off"></span><span
                                                                            className="switch-handle"></span>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className={"err_msg"}>*</span>Needs <i
                                                                    className="fa fa-info-circle" aria-hidden="true"
                                                                    data-toggle="tooltip"
                                                                    data-placement="right"
                                                                    title="What are the services  you want to trade with?"></i>
                                                                </td>
                                                                <td key={`needs${selProfile}`}>
                                                                    <div className="list_job" style={{margin: "auto"}}>
                                                                        <Jobs selectedJob={profile.req_jobs} onChange={(e)=>saveRequiredServices(e)} isMulti={true} />
                                                                        <Helper.ErrorMsg msg={profile.req_jobs_err}/>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className={"err_msg"}>*</span>Offering <i
                                                                    className="fa fa-info-circle" aria-hidden="true"
                                                                    data-toggle="tooltip"
                                                                    data-placement="right"
                                                                    title="What service are you offering to trade with?"></i>
                                                                </td>
                                                                <td key={`job${selProfile}`}>
                                                                    <div className="form-group">
                                                                        <Jobs selectedJob={profile.job_id} onChange={e => onChangeSelect("job_id", e)} />
                                                                        <Helper.ErrorMsg msg={profile.job_id_err}/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/*<tr>
                              <td>Headline</td>
                              <td>
                                <div className="form-group">Graphic designer looking for hairstylist, Graphic designer
                                  looking for painter
                                </div>
                              </td>
                            </tr>*/}
                                                            <tr>
                                                                <td>Overview <i className="fa fa-info-circle"
                                                                                aria-hidden="true"
                                                                                data-toggle="tooltip"
                                                                                data-placement="right"
                                                                                title="Summarize why they should select you and how you stand out."></i>
                                                                </td>
                                                                <td key={`overview${selProfile}`}><textarea
                                                                    onBlur={(e) => onChangeProfile(e)}
                                                                    style={{border: 0, color: "#777777"}}
                                                                    defaultValue={profile.overview ? profile.overview : ''}
                                                                    rows="4"
                                                                    cols="50" name="overview"></textarea>
                                                                    <Helper.ErrorMsg msg={profile.overview_err}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className={"err_msg"}>*</span>Experience</td>
                                                                <td key={`exp${selProfile}`}>
                                                                    <div className="form-group">
                                                                        <select className="selectpicker form-control"
                                                                                name="experience"
                                                                                onChange={(e) => onChangeProfile(e)}
                                                                                value={profile.experience ? profile.experience : ''}>
                                                                            {Helper.addOptions(expYears)}
                                                                        </select>
                                                                        <Helper.ErrorMsg msg={profile.experience_err}/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span
                                                                    className={"err_msg"}>*</span>Qualifications <i
                                                                    className="fa fa-info-circle" aria-hidden="true"
                                                                    data-toggle="tooltip"
                                                                    data-placement="right"
                                                                    title="Certified or licenced means you have a certificate or completing the job as per the set standards. Skilled means you know how the licenced document."></i>
                                                                </td>
                                                                <td key={`qual${selProfile}`}>
                                                                    <div className="form-group">
                                                                        <select className="selectpicker form-control"
                                                                                name="qualification"
                                                                                onChange={(e) => onChangeProfile(e)}
                                                                                value={profile.qualification ? profile.qualification : ''}>
                                                                            {Helper.addOptions(qualification)}
                                                                        </select>
                                                                        <Helper.ErrorMsg
                                                                            msg={profile.qualification_err}/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className={"err_msg"}>*</span>Hourly Rate</td>
                                                                <td key={`rate${selProfile}`}>
                                                                    <div className="form-group">
                                                                        <select className="selectpicker form-control"
                                                                                name="hourly_rate"
                                                                                onChange={(e) => onChangeProfile(e)}
                                                                                value={profile.hourly_rate ? profile.hourly_rate : ''}>
                                                                            {Helper.addOptions(hourlyRate)}
                                                                        </select>
                                                                        <Helper.ErrorMsg msg={profile.hourly_rate_err}/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className={"err_msg"}>*</span>Travel <i
                                                                    className="fa fa-info-circle" aria-hidden="true"
                                                                    data-toggle="tooltip"
                                                                    data-placement="right"
                                                                    title="How far am I willing to travel, if any to complete the trade"></i>
                                                                </td>
                                                                <td key={`travel${selProfile}`}>
                                                                    <div className="form-group">
                                                                        <select className="selectpicker form-control"
                                                                                name="travel_limit"
                                                                                onChange={(e) => onChangeProfile(e)}
                                                                                value={profile.travel_limit ? profile.travel_limit : ''}>
                                                                            {Helper.addOptions(distance)}
                                                                        </select>
                                                                        <Helper.ErrorMsg
                                                                            msg={profile.travel_limit_err}/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className={"err_msg"}>*</span>Mobile</td>
                                                                <td key={`mob${selProfile}`} className="editable_cont" onClick={()=>document.getElementById("mobile").focus()}>
                                                                    <InputMask type="text"
                                                                               id="mobile"
                                                                               name="mobile"
                                                                               value={profile.mobile ? profile.mobile : ''}
                                                                               onBlur={(e) => onChangeProfile(e)}
                                                                               placeholder="Mobile"
                                                                               onChange={e => setProfileState('mobile', e.currentTarget.value)}
                                                                               mask="(999) 999-9999"
                                                                               pattern="^\d{4}-\d{3}-\d{4}$" required/>
                                                                    <Helper.ErrorMsg msg={profile.mobile_err}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Business Phone</td>
                                                                <td key={`phone${selProfile}`} className="editable_cont" onClick={()=>document.getElementById("phone_business").focus()}>
                                                                    <InputMask type="text"
                                                                               id="phone_business"
                                                                               name="phone_business"
                                                                               value={profile.phone_business ? profile.phone_business : ''}
                                                                               onBlur={(e) => onChangeProfile(e)}
                                                                               placeholder="Business Phone"
                                                                               onChange={e => setProfileState('phone_business', e.currentTarget.value)}
                                                                               mask="(999) 999-9999"
                                                                               pattern="^\d{4}-\d{3}-\d{4}$" required/>
                                                                    <Helper.ErrorMsg msg={profile.phone_business_err}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Business Address</td>
                                                                <td key={`baddress${selProfile}`}
                                                                    className="editable_cont" onClick={()=>document.getElementById("address_business").focus()}>
                                                                    <Autocomplete
                                                                        style={{width: '80%'}}
                                                                        id="address_business"
                                                                        name="address_business"
                                                                        onBlur={e => onChangeProfile(e)}
                                                                        defaultValue={profile.address_business}
                                                                        onPlaceSelected={(place) => {
                                                                            setProfileState('address_business', place.formatted_address);
                                                                            setProfileState('address_business_err', '');
                                                                            let address = Object.assign({},
                                                                                Helper.getAddressComponents(place.address_components),
                                                                                {
                                                                                    'address_business': place.formatted_address,
                                                                                    lat: place.geometry.location.lat(),
                                                                                    lng: place.geometry.location.lng()
                                                                                });
                                                                            saveProfileField(address);
                                                                        }}
                                                                        types={['address']}
                                                                        componentRestrictions={{country: ["us", "ca"]}}
                                                                    />
                                                                    <Helper.ErrorMsg
                                                                        msg={profile.address_business_err}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className={"err_msg"}>*</span>Zip or Postal
                                                                    Code
                                                                </td>
                                                                <td key={`baddress${selProfile}`}
                                                                    className="editable_cont" onClick={()=>document.getElementById("zip_business").focus()}>
                                                                    <input type="text"
                                                                           id="zip_business"
                                                                           name="zip"
                                                                           maxLength={7}
                                                                           defaultValue={profile.zip}
                                                                           onBlur={(e) => onChangeProfile(e)}
                                                                           placeholder="Zip/Postal Code"/>
                                                                    <Helper.ErrorMsg msg={profile.zip_err}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Website</td>
                                                                <td key={`web${selProfile}`} className="editable_cont" onClick={()=>document.getElementById("website").focus()}>
                                                                    <input type="text"
                                                                           id="website"
                                                                           name="website"
                                                                           defaultValue={profile.website}
                                                                           onBlur={(e) => onChangeProfile(e)}
                                                                           placeholder="Website"/>
                                                                    <Helper.ErrorMsg msg={profile.website_err}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Linkedin</td>
                                                                <td key={`link${selProfile}`} className="editable_cont" onClick={()=>document.getElementById("linkedin").focus()}>
                                                                    <input type="text"
                                                                           id="linkedin"
                                                                           name="linkedin"
                                                                           defaultValue={profile.linkedin}
                                                                           onBlur={(e) => onChangeProfile(e)}
                                                                           placeholder="Linkedin"/>
                                                                    <Helper.ErrorMsg msg={profile.linkedin_err}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Facebook</td>
                                                                <td key={`face${selProfile}`} className="editable_cont" onClick={()=>document.getElementById("facebook").focus()}>
                                                                    <input type="text"
                                                                           id="facebook"
                                                                           name="facebook"
                                                                           defaultValue={profile.facebook}
                                                                           onBlur={(e) => onChangeProfile(e)}
                                                                           placeholder="Facebook"/>
                                                                    <Helper.ErrorMsg msg={profile.facebook_err}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Instagram</td>
                                                                <td key={`insta${selProfile}`}
                                                                    className="editable_cont" onClick={()=>document.getElementById("instagram").focus()}>
                                                                    <input type="text"
                                                                                                     id="instagram"
                                                                                                     name="instagram"
                                                                                                     defaultValue={profile.instagram}
                                                                                                     onBlur={(e) => onChangeProfile(e)}
                                                                                                     placeholder="Instagram"/>
                                                                    <Helper.ErrorMsg msg={profile.instagram_err}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Skills</td>
                                                                <td>
                                                                    <span className="coming_soon">Coming soon</span>
                                                                    <input type="text" name="skills"
                                                                           style={{display: 'none'}}/>
                                                                </td>
                                                            </tr>
                                                            {/*<!--   <tr>
                          <td>I will not do a Job for Less than</td>
                          <td className="editable_cont">$600<span className="edit_profile_i"><i className="fa fa-times-circle" aria-hidden="true"></i>
                            <i className="fa fa-check-circle" aria-hidden="true"></i></span>
                          </td>
                        </tr>-->*/}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div id="tab3" className="container tab-pane fade"><br/>
                                    <div className="table_container table-responsive">
                                        <table className="text-center">
                                            <tbody>
                                            <tr className="titles">
                                                <th><h4>Select a Plan</h4></th>
                                                <th><h4>Good</h4></th>
                                                <th><h4>Better</h4></th>
                                                <th><h4>Best</h4></th>
                                            </tr>
                                            <tr className="b_top">
                                                <td className="Plan">Pricing</td>
                                                <td><strong>FREE</strong><br/><br/>
                                                    <div className="price_link m_t">Your current plan</div>
                                                </td>
                                                <td><strong>CAD $4.99</strong>/MO<sup>1</sup><br/><span
                                                    className="anually">*billed annually</span><br/>
                                                    <div className="price_link"></div>
                                                </td>
                                                <td><strong>CAD $7.99</strong>/MO<sup>1</sup><br/><span
                                                    className="anually">*billed annually</span><br/>
                                                    <div className="price_link"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="Plan"># of trade per month</td>
                                                <td>1 Trade Only</td>
                                                <td>15</td>
                                                <td>30</td>
                                            </tr>
                                            <tr>
                                                <td className="Plan"># of job offers</td>
                                                <td>1</td>
                                                <td>3</td>
                                                <td>5</td>
                                            </tr>
                                            <tr>
                                                <td className="Plan"># searches per month</td>
                                                <td>10</td>
                                                <td>30</td>
                                                <td>50</td>
                                            </tr>
                                            <tr>
                                                <td className="Plan">Unlimited # of hires</td>
                                                <td className="check">&#10003;</td>
                                                <td className="check">&#10003;</td>
                                                <td className="check">&#10003;</td>
                                            </tr>
                                            <tr>
                                                <td className="Plan">Portfolio pictures per offer</td>
                                                <td>0</td>
                                                <td>3</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td className="Plan"># of search results</td>
                                                <td>4</td>
                                                <td>20</td>
                                                <td>40</td>
                                            </tr>
                                            <tr>
                                                <td className="Plan">Job alerts</td>
                                                <td>0</td>
                                                <td>5</td>
                                                <td>10</td>
                                            </tr>
                                            <tr>
                                                <td className="Plan">Website URLS</td>
                                                <td>1</td>
                                                <td>3</td>
                                                <td>5</td>
                                            </tr>
                                            <tr>
                                                <td className="Plan">Social media links</td>
                                                <td className="check">&#10003;</td>
                                                <td className="check">&#10003;</td>
                                                <td className="check">&#10003;</td>
                                            </tr>
                                            <tr>
                                                <td className="Plan"></td>
                                                <td>
                                                    <div className="price_link m_t"></div>
                                                </td>
                                                <td>
                                                    <div className="price_link">Upgrade Now</div>
                                                </td>
                                                <td>
                                                    <div className="price_link">Upgrade Now</div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div className="sales_text"><p><sup>1</sup>Sales tax not included</p></div>
                                    </div>
                                </div>
                                <div id="tab4" className="container tab-pane fade"><br/>
                                    <div className="manage_job_bg">
                                        <div className="manage_job"><span><i className="fa fa-user"></i>Manage Notifications</span>
                                        </div>
                                        <div className="row manage_noti">
                                            <div className="alert_notification col-lg-6">
                                                <h6 className="notification_heading">Alerts</h6>
                                                <div className="email_when">Email me when:</div>
                                                {/*<div className="notification_table">
                            <label className="switch-label-new" style={{margin: 0}}>
                              <input type="checkbox" name="email_match" onClick={e=>saveNotification(e)}
                                     checked={!user.email_match} className="switch-input"/>
                              <span className="switch-label" data-on="On" data-off="Off"></span><span
                                className="switch-handle"></span>
                            </label>
                            <span className="toggle-set"
                                  style={{verticalAlign: 'sub'}}>When the system has found my match</span>
                          </div>*/}
                                                <div className="notification_table">
                                                    <label className="switch-label-new" style={{margin: 0}}>
                                                        <input type="checkbox" name="email_accept"
                                                               onClick={e => saveNotification(e)}
                                                               checked={!user.email_accept} className="switch-input"/>
                                                        <span className="switch-label" data-on="On"
                                                              data-off="Off"></span><span
                                                        className="switch-handle"></span>
                                                    </label>
                                                    <span className="toggle-set" style={{verticalAlign: 'sub'}}>When someone accepts an offer</span>
                                                </div>
                                                <div className="notification_table">
                                                    <label className="switch-label-new" style={{margin: 0}}>
                                                        <input type="checkbox" name="email_decline"
                                                               onClick={e => saveNotification(e)}
                                                               checked={!user.email_decline} className="switch-input"/>
                                                        <span className="switch-label" data-on="On"
                                                              data-off="Off"></span><span
                                                        className="switch-handle"></span>
                                                    </label>
                                                    <span className="toggle-set"
                                                          style={{verticalAlign: 'sub'}}>When someone declines an offer</span>
                                                </div>
                                            </div>
                                            <div className="alert_notification col-lg-6">
                                                <h6 className="notification_heading">News</h6>
                                                <div className="email_when">Email me about:</div>
                                                <div className="notification_table">
                                                    <label className="switch-label-new" style={{margin: 0}}>
                                                        <input type="checkbox" name="email_updates"
                                                               onClick={e => saveNotification(e)}
                                                               checked={!user.email_updates} className="switch-input"/>
                                                        <span className="switch-label" data-on="On"
                                                              data-off="Off"></span><span
                                                        className="switch-handle"></span>
                                                    </label>
                                                    <span className="toggle-set" style={{verticalAlign: 'sub'}}>New features and updates</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab5" className="container tab-pane fade"><br/>
                                    <div className="manage_job_profile">
                                        <div className="manage_job_bg">
                                            <div className="manage_job">
                                                <span><i className="fa fa-user"></i>Manage Job Alerts</span>
                                                <a href="#add-alert" data-toggle="modal" className="add-alert_btn">
                                                    <span className="addAlert"> <i className="fa fa-plus-circle"
                                                                                   aria-hidden="true"></i>Add Alert</span>
                                                </a>
                                            </div>
                                            {showLoader ? <Helper.Loading/> :
                                                <div className="manage_table" style={{overflowX: "auto"}}>
                                                    <table className="manageSettings">
                                                        <thead>
                                                        <tr>
                                                            <th>Job title</th>
                                                            <th>Country</th>
                                                            <th>City</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <ListAlerts/>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="change-pass" role="dialog" aria-labelledby="changePass"
                     aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">Change Password
                                <button type="button" className="close" data-dismiss="modal"><span
                                    aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="setting_table" style={{overflowX: "auto"}}>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>Current Password</td>
                                            <td>
                                                <div className="form-group password-main">
                                                    <input type={showPass ? "text" : "password"} name={"pass"} autocomplete="new-password"
                                                           onBlur={(e) => {
                                                               setCurrentPass(e.currentTarget.value);
                                                               e.currentTarget.value && setErrCurrP('');
                                                           }}/>
                                                    {showPass ?
                                                        <span
                                                            className="fa fa-fw field-icon toggle-password fa-eye-slash"
                                                            onClick={() => setShowPass(!showPass)}></span>
                                                        : <span className="fa fa-fw fa-eye field-icon toggle-password"
                                                                onClick={() => setShowPass(!showPass)}></span>}
                                                    <Helper.ErrorMsg msg={errCurrP}/>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>New Password</td>
                                            <td>
                                                <div className="form-group password-main">
                                                    <input type={showNPass ? "text" : "password"} name={"new_pass"} autocomplete="new-password"
                                                           onBlur={(e) => {
                                                               setNewPass(e.currentTarget.value);
                                                               e.currentTarget.value && setErrNP('')
                                                           }}/>
                                                    {showNPass ?
                                                        <span
                                                            className="fa fa-fw field-icon toggle-password fa-eye-slash"
                                                            onClick={() => setShowNPass(!showNPass)}></span>
                                                        : <span className="fa fa-fw fa-eye field-icon toggle-password"
                                                                onClick={() => setShowNPass(!showNPass)}></span>}
                                                    <Helper.ErrorMsg msg={errNP}/>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Confirm Password</td>
                                            <td>
                                                <div className="form-group password-main">
                                                    <input type={showCPass ? "text" : "password"} name={"confirm_pass"} autocomplete="new-password"
                                                           onBlur={(e) => {
                                                               setConfirmPass(e.currentTarget.value);
                                                               e.currentTarget.value && setErrCP('')
                                                           }}/>
                                                    {showCPass ?
                                                        <span
                                                            className="fa fa-fw field-icon toggle-password fa-eye-slash"
                                                            onClick={() => setShowCPass(!showCPass)}></span>
                                                        : <span className="fa fa-fw fa-eye field-icon toggle-password"
                                                                onClick={() => setShowCPass(!showCPass)}></span>}
                                                    <Helper.ErrorMsg msg={errCP}/>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="save_cancel">
                                        <button className="button-style" disabled={disableSubmit}
                                                onClick={() => changePass()}>Submit
                                        </button>
                                    </div>
                                    <Helper.ErrorMsg msg={error}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddAlert loadAlerts={loadAlerts} setMessage={setMessage}/>
                <DeleteAlert id={delAlertId} loadAlerts={loadAlerts} setMessage={setMessage}/>
                <DeleteProfileAlert setUserState={setUserState}/>
                <Helper.commonModal message={message} class={messageClass}/>
            </section>
        </div>
    );
};

export default Setting;
