import React from 'react';
import axios from 'axios';
import * as Storage from './storage';
import { navigate } from "@reach/router";
import Loader from 'react-loader-spinner';
import * as Helper from './functions';


export const ScrollTop = () => {
    window.scrollTo({
        top: 0,
        left: 0
    });
};

export function axiosInstance() {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Storage.Get('user') ? Storage.Get('user').token : ''}`
        }
    })
};

export function logout() {
    localStorage.clear();
    navigate('/');
}


//Generic reuest error
export const RequestError = () => (<div className="alert alert-danger mt-4">Your request can not be submitted at the moment, please try again later.</div>);


export const NoRecordFound = () => (<div variant="warning">No record found.</div>);
export const ErrorMsg = (props) => (props.msg ? <div className={"err_msg_blk"}>{props.msg}</div> : null);

//Upper case the words
export const UCWords = (str) => str.replace('-', ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });


//HTML entity decode
export const htmlDecode = (html) => {
    var e = document.createElement('div');
    e.innerHTML = html;
    //return e.childNodes[0].nodeValue;
    return e.innerHTML;
}

//Get URL param
export const checkUrlContains = (name) => {
    if (window.location.href.indexOf(name) > -1) {
        return true;
    } else {
        return null;
    }
}

export const closest = (el, selector) => {
    var matchesFn;

    // find vendor prefix
    ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
        if (typeof document.body[fn] == 'function') {
            matchesFn = fn;
            return true;
        }
        return false;
    })

    var parent;

    // traverse parents
    while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
            return parent;
        }
        el = parent;
    }

    return null;
}


export function generateAvatar(user) {
    return <img style={{ height: '50px', width: '50px' }} className="mr-2 im-rounded" src={user.photo} alt={user.first_name} />;
}

// To reload the page in case of server error, on click of try again button
export function handleErrorClose() {
    window.location.reload();
}

export function isValidEmail(mail) {
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail);
}

/**
 * Validate US and Canada Zip
 * @param mail
 * @returns {boolean}
 */
export function isValidZip(mail) {
    return /^[0-9]{5}$|^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/.test(mail);
}

// To format date to MM/DD/YYYY
export function formatDate(date, type) {
    let monthNames = [
        "01", "02", "03",
        "04", "05", "06", "07",
        "08", "09", "10",
        "11", "12"
    ];

    let day = date.getDate(),
        monthIndex = date.getMonth(),
        year = date.getFullYear();
    day = day < 10 ? `0${day}` : day;
    if (type === 'checkKidExists') {
        return year + '-' + monthNames[monthIndex] + '-' + day;
    }
    else {
        return monthNames[monthIndex] + '/' + day + '/' + year;
    }
}

const setUserStorage = (mainObj, userData, response) => {
    if (mainObj) {
        mainObj.setState({
            showLoader: false
        });
        if (response === "success") {
            // login successful, perform your actions if any, for example: load contacts, getting unread message count, etc
            const userProps = mainObj.props.childProps;
            if (userProps) {
                Storage.Set('user', JSON.stringify(userData));
                userProps.userHasAuthenticated(true);
            }
        } else {
            // error in user login/register (you can hide chat button or refresh page)
            mainObj.setState({
                messageType: 'danger',
                message: 'Invalid username or password'
            });
        }
    }
}

export const showRating = (props) => {
    let fstar = <span className="fa fa-star"></span>,
        hstar = <span className="fa fa-star-half"></span>,
        estar = <span className="fa fa-star-o"></span>,
        stars = '';
    if(props.rating > 4.5){
        stars = <>{fstar}{fstar}{fstar}{fstar}{fstar}</>;
    }else if(props.rating > 4 && props.rating <= 4.5){
        stars = <>{fstar}{fstar}{fstar}{fstar}{hstar}</>;
    }else if(props.rating > 3.5 && props.rating <= 4){
        stars = <>{fstar}{fstar}{fstar}{fstar}{estar}</>;
    }else if(props.rating > 3 && props.rating <= 3.5){
        stars = <>{fstar}{fstar}{fstar}{hstar}{estar}</>;
    }else if(props.rating > 2.5 && props.rating <= 3){
        stars = <>{fstar}{fstar}{fstar}{estar}{estar}</>;
    }else if(props.rating > 2 && props.rating <= 2.5){
        stars = <>{fstar}{fstar}{hstar}{estar}{estar}</>;
    }else if(props.rating > 1.5 && props.rating <= 2){
        stars = <>{fstar}{fstar}{estar}{estar}{estar}</>;
    }else if(props.rating > 1 && props.rating <= 1.5){
        stars = <>{fstar}{hstar}{estar}{estar}{estar}</>;
    }else if(props.rating > .5 && props.rating <= 1){
        stars = <>{fstar}{estar}{estar}{estar}{estar}</>;
    }else if(props.rating > 0 && props.rating <= .5){
        stars = <>{hstar}{estar}{estar}{estar}{estar}</>;
    }
    return <div className="stars">{stars}{props.count ? <span style={{color:'#c9c9c9'}}>&nbsp;({props.count})</span> : null}</div>;
}

export function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export function validFbUrl(url) {
    return /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(url);
}
export function validLinkedUrl(url) {
    return /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/.*/i.test(url);
}
export function validInstaUrl(url) {
    return /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(url);
}

export const invalidPhone = phone => phone && phone != '(___) ___-____'
    && phone.replace(/_/g, "").replace('(','').replace(')','').replace('-','').replace(' ','').length < 10;

export const validPhone = phone => phone && phone.replace(/_/g, "").replace('(','').replace(')','').replace('-','').replace(' ','').length == 10;


export const Loading = () => (
  <div style={{textAlign:'center', marginBottom:'50px', marginTop:'50px'}}><Loader type="BallTriangle" color="#CCC" height={80} width={80} /></div>
);

export const btnLoader = () => (
    <Loader type="BallTriangle" color="#FFF" height={30} width={30} />
);

export const objSize = (obj) => {
    let size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
}

export const getAddressComponents = (address) => {
    let components = {};
    window.$.each(address, function(k,v1) {
        window.$.each(v1.types, function(k2, v2){
            components[v2]=v1.long_name
        });
    });
    return {
        city: components.locality,
        state: components.administrative_area_level_1,
        country: components.country,
        zip: components.postal_code,
    }
};

/**
 * If timeline is complete redirect the user to dashboard.
 */
export const redirectDashboard = () => {
    let user = Storage.Get('user');
    user.timeline_complete && navigate('/dashboard');
};

/**
 * Check strong pass
 * @param password
 * @returns {boolean}
 */
export const strongPass = (password) => {
    let pass = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    return pass.test(password);
};

export const addOptions = (options)=>{
    var arr = [];
    arr.push(<option value="0">Choose</option>);
    for (const [key, value] of Object.entries(options)) {
        arr.push(<option key={value.value} key={key} value={value.value}>{value.label}</option>)
    }
    return arr;
}
export const getQueryParam = (param)=>{
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export const commonModal = (props) => (
    <div className="modal fade c_info_custom" id="jsCommonModal" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-body1">
                    <button type="button" className="close" data-dismiss="modal">
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="contact_info_main success_modal">
                        <div className="contact_info_content">
                            <h5 className={props.class ? props.class : ''}>{props.message}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);


export const goToDashboard = (keyword) => {
    Helper.axiosInstance().post('job/list', {keyword: keyword})
        .then((res) => {

        })
        .catch((error) => { });

};

export const CopyToClipboard = (str) => {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
    window.$('.copy-url').show();
    setTimeout(function() { window.$('.copy-url').hide(); }, 2000);
}