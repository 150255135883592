import React from "react";
import { Link } from "@reach/router";

const VerifyAccount = (props) => {
  return (
    <div className="modal-box">
      <div className="modal-dialog">
        <div className="modal-content">
          <h2>Account Verification</h2> <Link to="" className="my-2 text-blue-700 hover:text-blue-800 text-center block" onClick={props.onCloseRequest}>x</Link>
          <div className="content">{props.message}</div>
          <div>
              <button className="button-style" onClick={props.onCloseRequest}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VerifyAccount;