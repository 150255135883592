import React, {useEffect, useRef, useState} from "react";
import {Link, navigate} from "@reach/router";
import Header from "./Header/Header";
import * as Helper from './functions';
import Jobs from "./Jobs";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    } from "react-share";

const DashBoard = (props) => {
    const [services, setServices] = useState([]);
    const [reqService, setReqService] = useState('');
    const [feedback, setFeedback] = useState('');
    const [selService0, setSelService0] = useState([]);
    const [selService0Err, setSelService0Err] = useState('');
    const [selService1, setSelService1] = useState([]);
    const [selService1Err, setSelService1Err] = useState('');
    const [selService2, setSelService2] = useState([]);
    const [selService2Err, setSelService2Err] = useState('');
    const [showLoader, setShowLoader] = useState(true);
    const [showTradeLoader, setShowTradeLoader] = useState(true);
    const [tradeList, setTradeList] = useState([]);
    const [error, setError] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const feedbackRef = useRef(null);
    const [message, setMessage] = useState('');
    const showSearch = props.location.search && props.location.search.indexOf('showSearch=true');

    useEffect(()=> {
        window.scrollTo(0, 0);
        loadData();

        //Get user profile data.
        Helper.axiosInstance().get('job/required-services')
            .then((res) => {
                setServices(res.data.jobs);
                setReqService(res.data.data);
                setShowLoader(false);
                if(res.data.data[0]){
                    setSelService0(res.data.data[0].req_jobs);
                }
                if(res.data.data[1]){
                    setSelService1(res.data.data[1].req_jobs);
                }
                if(res.data.data[2]){
                    setSelService2(res.data.data[2].req_jobs);
                }
                setTimeout(function () {
                    window.$('[data-toggle="tooltip"]').tooltip();
                }, 1000);

            })
            .catch((error) => {
                setShowLoader(false);
            });
    }, []);

    const TradeList = () => {
        let arr = [], selServ = [];
        if(tradeList.length > 0) {
            for (let i in tradeList) {
                arr.push(
                    <div key={i} className="job_details" onClick={()=>navigate(`/profile/${tradeList[i].profile_id}/${tradeList[i].req_job_id}`)}>
                        <div className="user_img">
                            { tradeList[i].photo && <img style={{
                                'width': '65px',
                                'height': '65px'
                            }} src={tradeList[i].photo} alt={tradeList[i].first_name} /> }
                        </div>
                        <div className="text">
                            <h6>{tradeList[i].first_name} {tradeList[i].last_name}</h6>
                            <Link className="remove_underline" to={`/profile/${tradeList[i].profile_id}/${tradeList[i].req_job_id}`}>
                                {tradeList[i].job_title}{" "} looking for{" "}{tradeList[i].req_job_title}
                            </Link>
                            <div className="img">
                                <img src="images/5.png" alt="" />
                                <span>{tradeList[i].address_business}</span>
                            </div>
                        </div>
                    </div>
                )}
        }else{
            arr.push(<div className="not_found" key="not_found">
                <h3>No record available in this section.</h3>
            </div>);
        }
        return (
            <>{arr}</>
        );
    };

    const loadData = ()=>{
        setShowTradeLoader(true);

        Helper.axiosInstance().post('trade/list', {})
            .then((res) => {
                setTradeList(res.data.data);
                setShowTradeLoader(false);
            })
            .catch((error) => {
                setShowTradeLoader(false);
            });
    };

    const saveRequiredServices = (service, profileId, index) => {
        if(index == 0){
            if(service && service.length > 5){
                setSelService0Err('You can select maximum 5 services.');
                return;
            }else if(!service || !service.length){
                setSelService0Err('You must select atleast one service.');
                return;
            }
            setSelService0Err('');
            setSelService0(service);
        }else if(index == 1){
            if(service && service.length > 5){
                setSelService1Err('You can select maximum 5 services.');
                return;
            }else if(!service || !service.length){
                setSelService1Err('You must select atleast one service.');
                return;
            }
            setSelService1Err('');
            setSelService1(service);
        }else if(index == 2){
            if(service && service.length > 5){
                setSelService2Err('You can select maximum 5 services.');
                return;
            }else if(!service || !service.length){
                setSelService2Err('You must select atleast one service.');
                return;
            }
            setSelService2Err('');
            setSelService2(service);
        }

        if(service == null){
            service = [];
        }
        const serve = JSON.stringify([...service])
        const data = {
            profile_id: profileId,
            serve: serve
        }
        Helper.axiosInstance().post('job/save-req-services-profile', data)
            .then((res) => { })
            .catch((error) => { });
    }

    const RequiredServices = () => {
        let arr = [], selServ = [], servErr, setSelectedJob;

        for (let index in reqService) {
            if(index == 0){
                selServ = selService0;
                setSelectedJob = setSelService0;
                servErr = selService0Err;
            }else if(index == 1){
                selServ = selService1;
                setSelectedJob = setSelService1;
                servErr = selService1Err;
            }else if(index == 2){
                selServ = selService2;
                setSelectedJob = setSelService2;
                servErr = selService2Err;
            }
            arr.push(<div style={{'marginBottom':'20px'}} key={index}>
                <span className="icon_img">
                    <i className="fa fa-wrench" aria-hidden="true" />{" "}
                    <p className="f_size">Service providers I'm looking to {reqService[index].title ? ` trade with as a(n) ${reqService[index].title}` : ' hire'}&nbsp;
                        <i class="fa fa-info-circle info" aria-hidden="true" data-toggle="tooltip" data-placement="right"
                            title="This area is where you list all the services providers you need. Our system will notify you as soon as a match is found."></i></p>
                </span>
                <div className="list_job">
                    <div>
                        <Jobs selectedJob={selServ} onChange={(e)=>saveRequiredServices(e, reqService[index].profile_id, index)} isMulti={true} />
                    </div>
                    <Helper.ErrorMsg msg={servErr} />
                </div>
                </div>
            )
        };
        return (arr)
    };

    const submitFeedback = () => {
        if (!feedback) {
            setError('Please enter the feedback.');
        } else {
            setDisableSubmit(true);
            let postData = {
                feedback: feedback
            }
            Helper.axiosInstance().post('user/feedback', postData)
                .then((res) => {
                    setDisableSubmit(false);
                    if (res.data.success) {
                        window.$('#modal-feedback').modal('hide');
                        feedbackRef.current.value = '';
                        setFeedback('');
                    }
                    setMessage(res.data.message);
                    window.$("#jsCommonModal").modal('show');
                })
                .catch((error) => {
                    setDisableSubmit(false);
                });
        }
    }

  return (
    <div>
      <Header showSearch={showSearch} page={"dashboard"} />
      <div>
        <section className="sidebar_custom">
          <div className="container-fluid">
            <div className="row content" style={{ overflow: "auto" }}>
              <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 sidenav">
                <ul className="nav nav-pills nav-stacked ">
                  <li className="active"><Link to="/dashboard">Dashboard</Link></li>
                  <li><Link to="/status">Status of Requests</Link></li>
                </ul>
              </div>
              <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 right_section no_padding">
                <div className="manage">
                  <div className="details latest_jobs">
                    <div className="icon_img fixed_top">
                      {" "}
                      <p>
                        <i className="fa fa-newspaper-o" aria-hidden="true" />{" "}
                        <span className="f_size">Latest Job Posts</span>
                      </p>
                    </div>
                    <div className="fixedLatest">
                        {showTradeLoader ? <Helper.Loading /> : <TradeList />}
                    </div>
                  </div>
                </div>
                <div className="icon_and_feed">
                    <div className="list_of_needs">{showLoader ?  <Helper.Loading/> : <RequiredServices />}</div>
                  <div className="social_icons">
                    <div className="details">
                      <p className="f_size">Invite Your friends</p>
                      <div className="icons">
                        <FacebookShareButton url="https://job4job.com/" quote="Search our secure database for the best possible service provider to get your work done.">
                          <span><i className="fa fa-facebook" /></span>
                        </FacebookShareButton>
                        <TwitterShareButton url="https://job4job.com/" title="Search our secure database for the best possible service provider to get your work done.">
                            <span><i className="fa fa-twitter" /></span>
                        </TwitterShareButton>
                        <LinkedinShareButton url="https://job4job.com/" quote="Search our secure database for the best possible service provider to get your work done.">
                          <span><i className="fa fa-linkedin" /></span>
                        </LinkedinShareButton>
                          <WhatsappShareButton url="https://job4job.com/" title="Search our secure database for the best possible service provider to get your work done.">
                          <span><i className="fa fa-whatsapp" /></span>
                        </WhatsappShareButton>
                        {/*<WhatsappShareButton url="https://job4job.com/" quote="Search our secure database for the best possible service provider to get your work done.">
                          <span><i className="fa fa-instagram" /></span>
                        </WhatsappShareButton>*/}
                        <button className={"share-button"} onClick={()=>Helper.CopyToClipboard('https://job4job.com/')}>
                          <span><i className="fa fa-copy" /></span>
                        </button>
                          <span className={"copy-url"}><br />Copied!</span>
                      </div>
                    </div>
                  </div>
                  <div className="feedback">
                    <div className="details">
                      <p className="f_size">Have Feedback?</p>
                      <div className="content_feed">
                        <span>We would love to hear from you</span>
                        <a href="#modal-feedback" data-toggle="modal"><button>Leave Feedback</button></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="modal fade" id="modal-feedback" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom_body">
                <h2>Feedback</h2>
                    <textarea rows={8} ref={feedbackRef} defaultValue={""} maxLength={1000} placeholder="Leave a comment" onKeyUp={()=>setError('')} onBlur={(e)=>setFeedback(e.currentTarget.value)} />
                    {error && <div className="err_msg">{error}</div>}
                    <button className="add-my-review" disabled={disableSubmit} onClick={()=>submitFeedback()} type="submit">{disableSubmit ? <Helper.btnLoader /> : `Submit`}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
        <Helper.commonModal message={message} />
    </div>
  );
};

export default DashBoard;
