import React, { useEffect } from "react";
import { Link, navigate } from "@reach/router";
import logo from '../logo.png';
import loading from '../load.jpg'
import * as Storage from './storage';
import '../App.css';
import * as Helper from "./functions";

const TradeHire = () => {
    const setTransactionType = (type) => {
        Storage.Set('tran', type);
        navigate('timeline');
    }
    useEffect(()=> {
        Helper.redirectDashboard();
        Storage.Set('percentage', 0);
    }, []);

    return (
        <div className="bgWhite">
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
            <div className="thanks">
                <div className="reload">
                    <img src={loading} className="trade-logo" alt="loading" />
                </div>
                <h2 className="thanks-title">What are you looking to do?</h2>
                <button onClick={()=>setTransactionType('t')} className="action-btn">Trade</button>
                <button onClick={()=>setTransactionType('h')} className="action-btn">Hire</button>
            </div>
        </div>
        </div>
    )
};
export default TradeHire;
