import React, { useState } from "react";
import { Link } from "@reach/router";
import firebase from "firebase";

const AccountStatus = (props) => {
    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
    return (
        <div className="modal-box">
            <div className="modal-dialog">
                <div className="modal-content">
                    <h2>Notification</h2>
                    <Link to="" className="my-2 text-blue-700 hover:text-blue-800 text-center block" onClick={props.onCloseRequest}>x</Link>
                    <div className="content">This account has been suspended.
                        <br />
                        <a href="mailto:job4job.site@gmail.com">Click here</a> to contact the website administrator.</div>
                    <div>
                        <button className="button-style" onClick={props.onCloseRequest}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AccountStatus;