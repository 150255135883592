import React, {useEffect, useState} from "react";
import logo from '../logo.png';
import VerifyEmail from './VerifyEmail';
import * as Helper from './functions';
import Geocode from "react-geocode";
import '../App.css';
import {Link} from "@reach/router";
import Recaptcha from 'react-google-invisible-recaptcha';
import {GoogleMapKey} from "./enum";

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [zip, setZip] = useState("");
    const [zipVal, setZipVal] = useState("");
    const [confirm, setConfirm] = useState('');
    const [hear_about, setAbout] = useState('');
    const [errorFirst, setFirstError] = useState('');
    const [errorLast, setLastError] = useState('');
    const [errorZip, setZipError] = useState('');
    const [errorEmail, setEmailError] = useState('');
    const [errorPass, setPassError] = useState('');
    const [errorConfirm, setConfirmError] = useState('');
    const [errorAbout, setAboutError] = useState('');
    const [mainError, setMainError] = useState('');
    const [modal, setModal] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [recaptchaOb, setRecaptchaOb] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showCPass, setShowCPass] = useState(false);


    //console.log(stateOptions, "test")
    const createUserWithEmailAndPasswordHandler = (event, email, password) => {
        event.preventDefault();
        recaptchaOb.execute();
    };

    useEffect(() => {
        sessionStorage.clear();
    }, []);

    const onResolved = async () => {
        try {
            let valid = true;
            if ( !sessionStorage.getItem('first_name') ) { setFirstError('Required field'); valid = false;}
            if (!sessionStorage.getItem('last_name')) { setLastError('Required field'); valid = false;}
            if (!sessionStorage.getItem('zip')) { setZipError('Required field'); valid = false;}
            else if (!Helper.isValidZip(sessionStorage.getItem('zip'))) { setZipError('Invalid Zip'); valid = false;}
            if (!sessionStorage.getItem('userEmail')) { setEmailError("Required field"); valid = false;}
            else if (!Helper.isValidEmail(sessionStorage.getItem('userEmail'))) { setEmailError("Invalid email"); valid = false;}
            if (!sessionStorage.getItem('userPassword')) { setPassError('Error a strong password'); valid = false;}
            if (!Helper.strongPass(sessionStorage.getItem('userPassword'))) { setPassError('Minimum 8 characters containing uppercase, lowercase and digit'); valid = false;}
            if (!sessionStorage.getItem('confirmPassword')) { setConfirmError('Enter same password as above'); valid = false;}
            if (sessionStorage.getItem('userPassword') !== sessionStorage.getItem('confirmPassword')) { setConfirmError('Password does not match'); valid = false;}
            if (!sessionStorage.getItem('hear_about')) { setAboutError('Choose any one option'); valid = false;}
            if(valid) {
                let data = {
                    first_name: sessionStorage.getItem('first_name'),
                    last_name: sessionStorage.getItem('last_name'),
                    zip: sessionStorage.getItem('zip'),
                    lat: sessionStorage.getItem('lat'),
                    lng: sessionStorage.getItem('lng'),
                    city: sessionStorage.getItem('city'),
                    state: sessionStorage.getItem('state'),
                    country: sessionStorage.getItem('country'),
                    email: sessionStorage.getItem('userEmail'),
                    password: sessionStorage.getItem('userPassword'),
                    confirm_password: sessionStorage.getItem('confirmPassword'),
                    hear_about: sessionStorage.getItem('hear_about')
                };

                setDisableSubmit(true);
                await Helper.axiosInstance().post('register', data)
                    .then((response) => {
                        setDisableSubmit(false);
                        if (response.data.success) {
                            console.log('response', response);
                            setModal(!modal);
                        }
                        else {
                            let e = response.data.error;
                            if ("first_name" in e) {
                                setFirstError(e["first_name"]);
                            }if ("last_name" in e) {
                                setLastError(e["last_name"]);
                            }if ("email" in e) {
                                setEmailError(e["email"]);
                            }if ("password" in e) {
                                setPassError(e["password"]);
                            }if ("confirm_password" in e) {
                                setConfirmError(e["confirm_password"]);
                            }if ("zip" in e) {
                                setZipError(e["zip"]);
                            }if ("hear_about" in e) {
                                setAboutError(e["hear_about"]);
                            }
                        }
                    })
                    .catch((error) => {
                        setDisableSubmit(false);
                    });
            }
        }
        catch (error) {
            if (email && password !== "") {
                setMainError(error.message)
            }
            console.error("Error signing in with password and email", error);
        }
    };

    /*const onBlurHandler = event => {
        const { name, value } = event.currentTarget;
        if(addressVal != value){
            setAddressError('Please select the address from the suggestions. Manual entry is not permitted.');
        }else{
            setAddressError('');
        }
    };*/

    const onChangeHandler = event => {
        const { name, value } = event.currentTarget;
        sessionStorage.setItem(name, value)
        if (name === "userEmail") {
            setEmail(value);
            setEmailError('');
        } if (name === "userPassword") {
            setPassword(value);
            setPassError('');
        } if (name === "first_name") {
            setFirstName(value);
            setFirstError('');
        } if (name === "last_name") {
            setLastName(value);
            setLastError('');
        } else if (name === "confirmPassword") {
            setConfirm(value);
            setConfirmError('');
        } else if (name === "hear_about") {
            setAbout(value);
            setAboutError('');
        } else if (name === "zip") {
            if (Helper.isValidZip(sessionStorage.getItem('zip'))) {
                Geocode.setApiKey(GoogleMapKey);
                // Geocode.setRegion("es");
                // Geocode.enableDebug();
                Geocode.fromAddress(value).then(
                    response => {
                        const { lat, lng } = response.results[0].geometry.location;
                        const {city, state, country} = Helper.getAddressComponents(response.results[0].address_components);
                        sessionStorage.setItem('lat', lat);
                        sessionStorage.setItem('lng', lng);
                        sessionStorage.setItem('city', city);
                        sessionStorage.setItem('state', state);
                        sessionStorage.setItem('country', country);
                    },
                    error => {
                        console.error(error);
                    }
                );
            }
            setZipError('');
        }
    };

    return (
        <div className="App">
            <header className="App-header">
                <a href="https://job4job.com/"><img src={logo} className="App-logo" alt="logo" /></a>
            </header>
            <div className="sign-left">
                <form className="form-style col-md-12">
                    <h1 className="text-3xl mb-2 text-center font-bold">Sign Up Now</h1>
                    <input type="text" className={errorFirst !== '' ? "error-style col-md-6" : ""} style={{
                        float: 'left',
                        marginBottom: 0
                    }} name="first_name" placeholder="First Name" id="first_name"
                        onChange={event => onChangeHandler(event)} required/>
                    <input type="text" className={errorLast !== '' ? "error-style col-md-6" : ""} style={{
                        float: 'right',
                        marginBottom: 0
                    }} name="last_name" placeholder="Last Name" onChange={event => onChangeHandler(event)} required/>
                    <br/>
                    <div style={{clear: "both"}}></div>
          {errorFirst !== null && <div className="valid-error2 col-md-6">{errorFirst}</div>}{errorLast !== null && <div className="valid-error2 col-md-6">{errorLast}</div>}
                    <div style={{clear: "both"}}></div>
                    <input maxLength={7} type="text" className={errorZip !== '' ? "error-style" : ""} style={{width: '100%'}} name="zip" placeholder="Zip or Postal Code" id="zip"
                           onChange={event => onChangeHandler(event)} required/>

                    {errorZip !== null && <div className="valid-error3">{errorZip}</div>}

                    <div style={{clear: "both"}}></div>
                    <input type="email" style={{width: '100%'}} className={errorEmail !== '' ? "error-style" : null} name="userEmail" value={email} placeholder="Your Email?" id="userEmail"
                        onChange={event => onChangeHandler(event)} required/>
                    <br/>
          {errorEmail !== null && <div className="valid-error3">{errorEmail}</div>}
                    <div className={"password-main"}>
                    <input type={showPass ? "text" : "password"} style={{width: '100%'}} className={errorPass !== '' ? "error-style" : null} name="userPassword" value={password} placeholder="Create your Password"
                        id="userPassword" onChange={event => onChangeHandler(event)} required/>
                        {showPass ?
                            <span className="fa fa-fw field-icon toggle-password2 fa-eye-slash" onClick={()=>setShowPass(!showPass)}></span>
                            : <span className="fa fa-fw fa-eye field-icon toggle-password2"  onClick={()=>setShowPass(!showPass)}></span> }
                    </div>
                    <div className={"password-main"}>
          {errorPass !== null && <div className="valid-error3">{errorPass}</div>}
                    <input type={showCPass ? "text" : "password"} style={{width: '100%'}} className={errorConfirm !== '' ? "error-style" : null} name="confirmPassword" placeholder="Confirm your Password" onChange={event => onChangeHandler(event)} required/>
                    {showCPass ?
                        <span className="fa fa-fw field-icon toggle-password2 fa-eye-slash" onClick={()=>setShowCPass(!showCPass)}></span>
                        : <span className="fa fa-fw fa-eye field-icon toggle-password2"  onClick={()=>setShowCPass(!showCPass)}></span> }
                    </div>
          {errorConfirm !== null && <div className="valid-error3">{errorConfirm}</div>}
                    <select name="hear_about" style={{width: '100%'}} className={errorAbout !== '' ? "error-style" : null}  onChange={event => onChangeHandler(event)} required>
                        <option value="">How did you hear about us?</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Facebook">Facebook</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Friend or family">Friend or family</option>
                        <option value="Other">Other</option>
                    </select>
                    <br/>
          {errorAbout !== null && <div className="valid-error4">{errorAbout}</div>}
                    <Recaptcha
                        ref={ ref => setRecaptchaOb(ref) }
                        sitekey={ '6LcaaOMZAAAAALW7H0d1Qf7UPy0Td36U5xL-Hh0r' }
                        onResolved={ onResolved } />

                    <button className="sign-button" disabled={disableSubmit} onClick={event => {
                        createUserWithEmailAndPasswordHandler(event, email, password);
                    }}>{disableSubmit ? <Helper.btnLoader /> : `Let's Begin`}</button>
                </form>
        {mainError !== null && <div className="valid-error4">{mainError}</div>}
            </div>
            <div className="sign-right">
                <h2 className="sign-title">Save money, build contacts, and strengthen your community.</h2>
                <p className="sign-text">Our innovative way of trading services. We match your needs with qualified and capable individuals.</p>
                <ul>
                    <li>Become a service provider and offer your services</li>
                    <li>Browse our selection of service providers</li>
                    <li>Promote your business services locally</li>
                </ul>
            </div>
      {modal ? <VerifyEmail onCloseRequest={() => setModal(false)}/> : null}
        </div>
    );
};
export default SignUp;
