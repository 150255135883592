import React, {useContext} from "react";
import { Router } from "@reach/router";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ProfilePage from "./ProfilePage";
// import PasswordReset from "./PasswordReset";
import TradeHire from "./TradeHire";
import Timeline from "./Timeline";
import DashBoard from "./DashBoard";
import StatusTradeHire from "./StatusTradeHire/StatusTradeHire"
import Trade from "./Trade/Trade";
// import Pricing from "./Pricing/Pricing";
// import Happen from "./Happen/Happen";
import JobProfile from "./JobProfile";
import Setting from "./Setting/Setting";
import * as Storage from './storage';
// import { UserContext } from './UserProvider';

function Application() {
    const user = null;
    return (
        <Router>
            <PublicRoute as={SignUp} path="/signUp" exact />
            <PublicRoute as={SignIn} path="/" exact />
            <PublicRoute as={SignIn} path="/login" exact />
            <PrivateRoute as={ProfilePage} path="/profile" exact />
            <PrivateRoute as={TradeHire} path="/tradeHire" exact />
            <PrivateRoute as={Timeline} path="/timeline" exact />
            <PrivateRoute as={DashBoard} path="/dashboard" exact />
            <PrivateRoute as={StatusTradeHire} path="/status" exact />
            <PrivateRoute as={Trade} path="/trade" exact />
            <PrivateRoute as={JobProfile} path="/profile/:id/:reqJobId" exact />
            <PrivateRoute as={JobProfile} path="/profile/:id" exact />
            {/*<PrivateRoute as={Pricing} path="/pricing" exact />*/}
            {/*<PrivateRoute as={Happen} path="/happen" exact />*/}
            <PrivateRoute as={Setting} path="/setting" exact />
        </Router>
    );
}

class PrivateRoute extends React.Component {
    //static contextType = useContext(UserContext);
    render() {
        let { as: Comp, ...props } = this.props;
        return isLogin() ? <Comp {...props} /> : <SignIn />;
    }
}

class PublicRoute extends React.Component {
    //static contextType = useContext(UserContext);
    render() {
        let { as: Comp, ...props } = this.props;
        return isLogin() ? <ProfilePage /> : <Comp {...props} />;
    }
}

const isLogin  = () => {
    let user = Storage.Get('user'), now = new Date().getTime().toString();
    if(user){
        let loginTime = now - user.time, sessionTime = 60*60*1000; //1 hour session time.
        if(loginTime > sessionTime){
            Storage.Delete('user');
            return false;
        }else{
            user['time'] = now;
            Storage.Set('user', JSON.stringify(user));
            return true;
        }
    }else{
        return false;
    }    
};
export default Application;
