import React, { Component } from "react";
import '../App.css';

class ProgressInTracker extends Component {
    render() {
        return (
            <div className="inner-tracker" style={{width: this.props.percentage+"%"}}></div>
        ) 
    }
};
export default ProgressInTracker;