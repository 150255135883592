import React, { Component } from "react";
import ProgressInTracker from '../Components/ProgressInTracker';
import '../App.css';

class ProgressBar extends Component {
    render() {
        const percentageLimits = (min, value, max) => {
            return Math.min(Math.max(min, value), max);
        }
        return (
            <div className="tracker-style">
                <ProgressInTracker percentage={percentageLimits(0, this.props.percentage, 100)} />
            </div>
        ) 
    }
};
export default ProgressBar;