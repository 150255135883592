import React, { useState, useEffect, useContext } from "react";
import { Link, navigate } from "@reach/router";
import logo from '../logo.png';
import * as Storage from './storage';
import '../App.css';
import { UserContext } from './UserProvider';
import * as Helper from "./functions";

const ProfilePage = () => {
    const user = Storage.Get('user');
    const [profileName, setProfileName] = useState('');

    useEffect(() => {
        Helper.redirectDashboard();
        setProfileName(user.first_name + " " + user.last_name);
    }, [user]);

    return (
        <div className="bgWhite">
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <button className="button-style thanks-sign" onClick={()=>Helper.logout()}>Sign out</button>
            </header>
            <div className="thanks">
                <h2 className="thanks-title">Hi {profileName}.<br />Thanks for signing up</h2>
                {/*<p className="thanks-text">Don't worry it's quick</p>*/}
                <Link to="/tradeHire" className="thanks-btn">Let's start</Link>
            </div>
        </div>
        </div>
    )
};

export default  ProfilePage;
