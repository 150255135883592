import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content : {
        top                   : '20%',
        left                  : '40%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-20%, -50%)'
    }
};
export const SuccessAlert = (props) => (
<Modal
    isOpen={props.show}
    style={customStyles}
    onRequestClose={props.handleClose}
    contentLabel="Example Modal"
>

                <div className="modal-header">
                    <h2>Success</h2>
                    <button type="button" className="close" onClick={props.handleClose}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body custom_body">
                    <div>{props.message}</div>
                </div>

        </Modal>

);