import React from "react";
import * as Helper from './functions';
import AsyncSelect from 'react-select/async';

const Jobs = (props) => {
    const fetchData = (keyword, callback) => {
        if (!keyword) {
            callback([]);
        } else {
            Helper.axiosInstance().post('job/search', {keyword: keyword})
                .then((res) => {
                    callback(res.data.data);
                })
                .catch((error) => {
                });
        }
    };

    return (
          <AsyncSelect
            value={props.selectedJob}
            loadOptions={fetchData}
            placeholder={props.placeholder ? props.placeholder : "Type to choose service"}
            isMulti={props.isMulti ? props.isMulti : false}
            className={props.className ? props.className : null}
            noOptionsMessage={(e)=>e.inputValue ? 'No options for "'+e.inputValue+'"' : 'Type to choose'}
            onChange={(e) => {
                if(props.onChange){
                    props.onChange(e);
                }else{
                    props.setSelectedJob(e);
                }
            }}
            defaultOptions={false}
        />
    );
};
export default Jobs;
