import React, {useState, useEffect} from "react";
import {Link, navigate} from "@reach/router";
import logo from '../logo.png';
import '../App.css';
import PasswordReset from "./PasswordReset";
import VerifyAccount from "./VerifyAccount";
import AccountStatus from "./AccountStatus";
import Iframe from 'react-iframe';
import * as Helper from './functions';
import * as Storage from './storage';
import {GoogleLogin} from 'react-google-login';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorEmail, setEmailError] = useState('');
    const [errorPass, setPassError] = useState('');
    const [errorLogin, setLoginError] = useState(false);
    const [errorRP, setRPError] = useState('Validating link, please wait...');
    const [rpToken, setRPToken] = useState(null);
    const [verifyModal, setVerifyModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [verifyMessage, setVerifyMessage] = useState("Verifying your account, please wait...");
    const [modal, setModal] = useState(false);
    const [modalSec, setModalSec] = useState('fp');
    const [isChecked, setChecked] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [timelineComplete, setTimelineComplete] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('t');
        if (token != null) {
            setVerifyModal(true);
            const data = {token: token}
            Helper.axiosInstance().post('user/verify', data)
                .then((res) => {
                    console.log('response', res);
                    res.data.data.time = new Date().getTime();
                    Storage.Set('user', JSON.stringify(res.data.data));
                    setVerifyMessage(res.data.message);
                    setTimelineComplete(res.data.data.timeline_complete);
                })
                .catch((error) => {
                });
        } else {
            const rp = urlParams.get('rp');
            if (rp != null) {
                setRPToken(rp);
                setModal(true);
                setModalSec('vt');
                const data = {token: rp}
                Helper.axiosInstance().post('password/validate-token', data)
                    .then((res) => {
                        if (res.data.success) {
                            setModalSec('rp');
                        } else {
                            setRPError(res.data.message);
                        }
                    })
                    .catch((error) => {
                    });
            }

        }
        console.log('token', token);
    }, [])

    const signInWithEmailAndPasswordHandler = async (event, email, password) => {
        event.preventDefault();
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let valid = true;
        if (email === '') {
            setEmailError("Enter an email");
            valid = false;
        } else if (!re.test(email)) {
            setEmailError("Enter a valid email");
            valid = false;
        }
        if (password === '') {
            setPassError("Enter password");
            valid = false;
        }
        if (valid) {
            let data = {
                email: email,
                password: password
            };
            setLoginError(false);
            setDisableSubmit(true);
            await Helper.axiosInstance().post('login', data)
                .then((res) => {
                    if (res.data.success) {
                        res.data.data.time = new Date().getTime();
                        Storage.Set('user', JSON.stringify(res.data.data));
                        if (res.data.data.timeline_complete) {
                            navigate('dashboard');
                        } else {
                            navigate('profile');
                        }
                    } else {
                        if (res.data.data != undefined) {
                            if (res.data.data.status == 'I') {
                                setStatusModal(true);
                            } else if (res.data.data.status == 'PV') {
                                setLoginError(true);
                                setErrorMessage('You account verification is pending, please check your email for verification.');
                            }
                        } else {
                            setLoginError(true);
                            setErrorMessage('Invalid email or password.');
                        }

                    }
                    setDisableSubmit(false);
                })
                .catch((error) => {
                });
        }
    };

    const responseGoogle = async (response) => {
        if (response.profileObj != undefined) {
            let user = response.profileObj;
            setDisableSubmit(true);
            /*let data = {
                first_name: user.familyName,
                last_name: user.givenName,
                photo: user.imageUrl,
                email: user.email
            };*/
            await Helper.axiosInstance().post('login-google', response.profileObj)
                .then((response) => {
                    setDisableSubmit(false);
                    if (response.data.success) {
                        //console.log('response', response);
                        response.data.data.time = new Date().getTime();
                        Storage.Set('user', JSON.stringify(response.data.data));
                        navigate('profile');
                    } else {
                        setLoginError(true);
                    }
                })
                .catch((error) => {
                });
        }
    }

    const onChangeHandler = (event) => {
        event.preventDefault();
        const {name, value} = event.currentTarget;
        if (name === 'userEmail') {
            setEmail(value);
            setEmailError('');
        } else if (name === 'userPassword') {
            setPassword(value);
            setPassError('');
        }
    };

    const rememberFunction = (event) => {
        setChecked(event.currentTarget.checked)
    }

    return (
        <div className="App">
            <header className="App-header">
                <a href="https://job4job.com/"><img src={logo} className="App-logo" alt="logo"/></a>
            </header>
            <div className="pull-left">
                <Iframe url="https://youtube.com/embed/5FSVGOiwW2A?&rel=0" width="450px" height="248px" id="myId"
                        display="initial" position="relative"/>
            </div>
            <div className="pull-right">
                <h1 className="login-header">Sign In</h1>
                <h1 className="signup-header">or &nbsp;
                    <Link to="/signUp" className="link-color">create an account</Link>
                </h1>

                <GoogleLogin
                    clientId="966588280834-d35jab3vv79np5jh2gj1er2n34m5i7ii.apps.googleusercontent.com"
                    render={renderProps => (
                        <button onClick={renderProps.onClick} disabled={renderProps.disabled}
                                className="button-style">Sign in with Google</button>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />
                <hr className="line-or"/>
                <p className="text-center my-3 or-text">or</p>
                <hr className="line-or"/>
                {errorLogin && <p className="remember-text" style={{'color': '#FF0000'}}>{errorMessage}</p>}
                <div>
                    <form className="login-form" onSubmit={(event) => {
                        signInWithEmailAndPasswordHandler(event, email, password)
                    }}>
                        <input type="email" className={errorEmail !== '' ? "error-style" : ""} name="userEmail"
                               value={email} id="userEmail" placeholder="Email"
                               onChange={(event) => onChangeHandler(event)} required/>
                        <br/>
                        {errorEmail !== null && <div className="valid-error">{errorEmail}</div>}
                        <div className={"password-main"}>
                            <input type={showPass ? "text" : "password"}
                                   className={errorPass !== '' ? "error-style" : ""} name="userPassword"
                                   placeholder="Password" value={password} id="userPassword"
                                   onChange={(event) => onChangeHandler(event)} required/>
                            {showPass ?
                                <span className="fa fa-fw field-icon toggle-password2 fa-eye-slash"
                                      onClick={() => setShowPass(!showPass)}></span>
                                : <span className="fa fa-fw fa-eye field-icon toggle-password2"
                                        onClick={() => setShowPass(!showPass)}></span>}
                            {errorPass !== null && <div className="valid-error">{errorPass}</div>}
                        </div>

                        <p className="captcha">This page is protected by reCAPTCHA and is subject to the Google&nbsp;
                            <span className="link-color">Privacy Policy</span>&nbsp;
                            and&nbsp;
                            <span className="link-color">Terms of Service</span>
                            .</p>
                        <p className="remember-text">
                            <input type="checkbox" name="check" checked={isChecked} style={{'float': 'left','width': '16px'}}
                                   onChange={(event) => rememberFunction(event)}/>
                            <span style={{'float': 'left', 'margin-top': '10px'}}>&nbsp;Remember me</span></p>
                        <button type={"submit"} className="button-style sign-btn" style={{'position': 'relative'}} disabled={disableSubmit}>{disableSubmit ? <span style={{
                            'position': 'absolute',
                            'top': '3px',
                            'left': '36%'
                        }}><Helper.btnLoader/></span> : `Sign in`}</button>
                    </form>
                    <Link to="" className="link-color forget-text" onClick={() => {
                        setModalSec('fp');
                        setModal(!modal)
                    }}>Forgot your password?</Link>
                    {modal ? <PasswordReset rpMsg={errorRP} token={rpToken} section={modalSec}
                                            onCloseRequest={() => setModal(false)}/> : null}
                    {verifyModal ? <VerifyAccount
                        message={verifyMessage}
                        onCloseRequest={() => {
                            setVerifyModal(false)
                            if(timelineComplete){
                                navigate('dashboard');
                            } else {
                                navigate('profile');
                            }
                        }}/> : null}
                    {statusModal ? <AccountStatus
                        onCloseRequest={() => setStatusModal(false)}/> : null}
                </div>
            </div>
        </div>
    );
};
export default SignIn;
