import React, { useEffect, useState } from "react";
import * as Helper from './functions';

const DeleteProfilePic = (props) => {
    const [disableSubmit, setDisableSubmit] = useState(false);

    const deleteAlert = ()=>{
        setDisableSubmit(true);
        let data = {};
        Helper.axiosInstance().post('user/delete-profile-image', data)
            .then((res) => {
                setDisableSubmit(false);
                props.setUserState('photo', res.data.imageUrl);
                window.$("#delete-photo-alert").modal('hide');
            })
            .catch((error) => {
                setDisableSubmit(false);
            });
    }


     return (
         <>
        <div className="modal fade" id="delete-photo-alert" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">Delete Alert
                <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <div className="setting_table" style={{ overflowX: "auto" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>Do you really want to delete the profile photo?</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="save_cancel">
                    <button className="button-style" disabled={disableSubmit} onClick={()=>deleteAlert()}>{disableSubmit ?  <Helper.btnLoader /> : `Confirm`}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </>
  );
};

export default DeleteProfilePic;
